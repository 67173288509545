import React from 'react';
import logo from "./img/logo.svg";
import styles from "./Header.module.css";

const Header = () => {

    return (
        <div className={styles.bg}>
            <nav className={styles.nav}>
                    <img src={logo} alt="Živy chleb" className={styles.Logo}
                         /*onClick={localStorage.clear}*/
                    />
            </nav>
        </div>
    );
};

export default Header;
