import React, {useContext} from 'react';
import {Context} from "../index";
import {Route, Routes} from 'react-router-dom';
import {authRoutes, publicRoutes} from "../routes";
import List from "../pages/List";

const AppRouter = (props) => {
    const {user} = useContext(Context)

    //console.log(user)
    return (
        <Routes>
            {user.isAuth && authRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component {...props}/>} />
            )}
            { publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component {...props}/>} />
            )}
            <Route path={'/*'} element={<List {...props}/>} />
        </Routes>
    );
};

export default AppRouter;
