import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._types = []
        this._doughs = []
        this._additives = []
        this._allergens = []
        this._products = [{variants: [], types: [], allergens: [],}]
        this._deletedProducts = []
        this._page = 1
        this._totalCount = 0
        this._limit = 3
        this._currentProduct = {variants: [], types: [], allergens: []}
        this._currentVariant = {variant_type_additives: [{type: [], additives: []}]}
        this._changesA = false
        makeAutoObservable(this)
    }

    setTypes(types) {
        this._types = types
    }

    setDoughs(doughs) {
        this._doughs = doughs
    }

    setDeletedProducts(deletedProducts) {
        this._deletedProducts = deletedProducts
    }

    setCurrentProduct(currentProduct) {
        this._currentProduct = currentProduct
    }

    setCurrentVariant(currentVariant) {
        this._currentVariant = currentVariant
    }

    setAdditives(additives) {
        this._additives = additives
    }

    setAllergens(allergens) {
        this._allergens = allergens
    }

    setProducts(products) {
        this._products = products
    }

    setPage(page) {
        this._page = page
    }

    setTotalCount(count) {
        this._totalCount = count
    }

    get types() {
        return this._types
    }

    get doughs(){
        return this._doughs
    }

    get currentProduct() {
        return this._currentProduct
    }

    get currentVariant() {
        return this._currentVariant
    }

    get additives() {
        return this._additives
    }

    get allergens() {
        return this._allergens
    }

    get products() {
        return this._products
    }

    get totalCount() {
        return this._totalCount
    }

    get page() {
        return this._page
    }

    get limit() {
        return this._limit
    }

    get deletedProducts() {
        return this._deletedProducts
    }

    setChangesA(changes) {
        this._changesA = changes;
    }

    get changesA() {
        return this._changesA
    }

}
