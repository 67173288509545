import React from 'react';
import {ServerStyleSheet, StyleSheetManager} from "styled-components";
import {renderToString} from "react-dom/server";

/**
 * exportToHtml(content)
 * convert react component (content) to HTML.
 *
 Don't use className!!! -  in this case styles will be lost in HTML file!
 Content must use styled-component or style={{...}}!
 Don't use Bootstrap or something like!

 return html as string
 */

const exportToHtml = (content) => {
    const sheet = new ServerStyleSheet();

    try {
        const html = renderToString(
            <StyleSheetManager sheet={sheet.instance}>
                {content}
            </StyleSheetManager>
        );
        const styleTags = sheet.getStyleTags();
        // or
        // const styleTags = sheet.getStyleElement();
        const res = `
            <head>
            <meta charset="utf-8">
            <title>Bakery-Orders</title>
            </head>\n
            ${styleTags}\n${html}
        `
        //console.log(styleTags);
        //console.log(html);
        return (res);
    } catch (error) {
        // handle error
        console.error(error);
    } finally {
        sheet.seal();
    }

};

export default exportToHtml;