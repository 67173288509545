import React from 'react';

const TextHead = ({sortFields}) => {

    const SortComp = ({sortKey}) => {
        return (
           <span style={{fontSize:16, paddingBottom:8, color:sortFields[sortKey] ?'rgba(44,25,2,90)' : 'transparent'}}
           >&#8691;</span>
           /* <></>*/
        )
    }
    return (
        <thead>
        <tr style={{verticalAlign: 'middle',fontStyle: 'normal', backgroundColor: '#d4d4d4', height: 40,
            padding: "0 8px 0 8px"}}>
            <th>Datum rez.<SortComp sortKey="createdAt"/></th>
            <th>Č.rez. <SortComp sortKey="orderNumber"/></th>
            <th>Dat.dodání<SortComp sortKey="orderDate"/></th>
            <th>Č.vyrobku <SortComp sortKey="productCode"/></th>
            <th>Zákazník <SortComp sortKey="client"/></th>
            <th>Email <SortComp sortKey="email"/></th>
            <th>Stav <SortComp sortKey="bpState"/></th>
        </tr>
        </thead>
    )
        ;
};

export default TextHead;