import React, {useEffect} from 'react';
import Product from "../components/productComponets/Product";
import {observer} from "mobx-react-lite";
import {max} from "../utils/sorti";
import {makeId} from "../utils/dateTime";
import ContentTopMenu from "../components/productListComponents/ContentTopMenu";

const NewProduct = observer(({product, setProduct, products,...props}) => {

    const setProductHandler = (prod) => {
        setProduct({...prod, id: makeId(), innerStatus: "NewProduct", number: max(products?.map(p => p.number)) + 1})
    }
    useEffect(() => {
        const prod = {name: "", variants: [], types: [], allergens: [],}
        setProductHandler(prod)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <ContentTopMenu {...props}/>
            <Product product={product}
                     setProduct={setProductHandler}
            />
        </div>
    );
});

export default NewProduct;
