import {makeAutoObservable} from "mobx";

export default class BasketStore {
    constructor() {
        this._baskets = [{basket_products: []}]
        this._rBaskets = [{basket_products: []}]
        this._stateNamesAndCount = {}
        this._stateFilter = "all"
        this._basketXY = {top: 0, left: 0, innerWidth: 0, innerHeight: 0};
        makeAutoObservable(this)
    }

    setStateFilter(val) {
        this._stateFilter = val
    }

    get stateFilter() {
        return this._stateFilter
    }

    setRBaskets(rBaskets) {
        this._rBaskets = rBaskets
    }

    get rBaskets() {
        return this._rBaskets
    }

    setBaskets(baskets) {
        this._baskets = baskets
    }

    get baskets() {
        return this._baskets
    }

    setStateNamesAndCount(obj) {
        this._stateNamesAndCount = obj
    }

    get stateNamesAndCount() {
        return this._stateNamesAndCount
    }
    get basketXY() {
        return this._basketXY
    }
    setBasketXY(obj) {
        this._basketXY = obj
    }
}