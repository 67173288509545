import React, {useState} from 'react';
import {Form, Image} from "react-bootstrap";
import x_close_btn from "../../img/icon-delete.png"
import styles from "./NinaInput.module.css";
import MyInputNumber from "../UI/MyInputNumber";

const NinaInput = ({val, setValue, inputType, className, label}) => {
    const [showField, setShowField] = useState(!!val);
    const removeField = () => {
        setShowField(false)
        inputType === "number" ? setValue(0) : setValue("")
    };
    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}
            {showField || !!val ?
                <div>
                    {inputType === "text" ?
                        <Form.Control className="overflow-hidden"
                                      as="textarea" rows={1}
                                      type={inputType}
                                      value={val}
                                      placeholder={inputType === "text" ? "" : 0}
                                      onChange={e => setValue(e.target.value)}
                        /> :
                        <MyInputNumber value={val}
                                       className={className}
                                       onChange={v => setValue(v)}
                        />

                    } <Image src={x_close_btn} className={styles.x_close}
                             onClick={removeField}/>

                </div>
                :
                <div className="button-text-only" onClick={() => setShowField(true)}>Přidat položku</div>
            }
        </div>

    );
};

export default NinaInput;
