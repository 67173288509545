import React, {useContext, useState} from 'react';
import {Col, Container, Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {useLocation, useNavigate} from "react-router-dom";
import {LIST_ROUTE, LOGIN_ROUTE} from "../utils/consts";
import {login, registration} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const Auth = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const click = async () => {
        try {
            let data;
            if (isLogin) {
                data = await login(email, password);
            } else {
                data = await registration(email, password);
            }
            if (data.role === "ADMIN") {
                user.setIsAdmin(true)
                user.setIsAdminPanelVisible(false)
            } else {
                //throw new Error("You are not admin")
                user.setIsAdmin(false)
            }

            user.setUser(data)
            user.setIsAuth(true)

            navigate(LIST_ROUTE)
        } catch (e) {
            alert("Authorization error or server is not available now. Please try again later.")
        }

    }

    return (
        <Container
            className="d-flex m-5"
        >
            <Card style={{width: 600, height:250}} className="p-5">
                <Form className="d-flex flex-column">
                    <Form.Control
                        className="mt-3"
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                    <Row className="d-flex justify-content-between mt-3 pl-2 pr-2">
                        <Col md={9}>
                            {/*{isLogin ?
                                <div>
                                    Don't have an account? <a href={PRODUCT_LIST_ROUTE}>Register</a>
                                </div>
                                :
                                <div>
                                    Already have an account? <a href={LOGIN_ROUTE}>Login</a>
                                </div>
                            }*/}
                        </Col>
                        <Col md={3}>
                            <Button className="mr-5"
                                variant=""
                                onClick={click}
                            >
                                {isLogin ? 'Login' : 'Registration'}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </Card>
        </Container>
    );
});

export default Auth;
