import React from 'react';
import styles from "./reservedProducts.module.css";
import {bpStateObj} from "./basketProductStates";
import InputDropdown from "../commonComponents/InputDropdown";


const IssueStatus = ({state, setState}) => {

    const borderColor = Object.values(bpStateObj)?.find(e => e.name === state)?.borderColor || "white"

    return (
        <div>
            <InputDropdown titleStyle={styles.status} borderColor={borderColor} state={state}
                           setState={setState} bpStateObj={bpStateObj}/>
        </div>
    );
};


export default IssueStatus;




