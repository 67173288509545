import React, { useState, useEffect } from 'react';
import MyInputNumber from "./UI/MyInputNumber";

const Timer = ({}) => {
    const [seconds, setSeconds] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [inputVal, setInputVal] = useState(0);


    const startTimer = () => {
        const id = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);

        setIntervalId(id);
    };

    const stopTimer = () => {
        clearInterval(intervalId);
        setIntervalId(null);
    };

    const resetTimer = () => {
        stopTimer();
        setSeconds(0);
    };

    const handleStartClick = () => {
        if (!intervalId) {
            startTimer();
        }
    };

    const handleStopClick = () => {
        if (intervalId) {
            stopTimer();
        }
    };
    useEffect(() => {
        if (seconds === inputVal) {
            // По достижении 10 секунд, выполните необходимые действия
            //alert("Таймер завершен!");
            resetTimer();
        }
    }, [seconds]);

    return (
        <div>
            <h1>Таймер</h1>
            <p>{inputVal-seconds}</p>
            { !intervalId &&
                <div>
                    <MyInputNumber value={inputVal} onChange={setInputVal} />
                    <button onClick={handleStartClick}>Старт</button>
                </div>
            }
            <button onClick={handleStopClick}>Стоп</button>
        </div>
    );
};

export default Timer;