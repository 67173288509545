import {$authHost, $host} from "./index";

export const fetchQualities = async () => {
    const {data} = await $host.get('api/quality')
    return data
}
export const updateQualities = async (qs) => {
    const {data} = await $authHost.post('api/quality/update',qs)
    return data
}
