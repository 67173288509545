import React, {useContext} from 'react';
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const ContentTopMenu = observer(({yesBtnHandler, cancelHandler, publishBtnHandler}) => {
    const {productStore} = useContext(Context)
    const maybeBlue = Boolean(productStore.products.find(p => p.isItChanged) || productStore.changesA)
    return (
        <Row>
            <Col sm={8} className="">
                <div className="d-flex flex-nowrap">
                    <button className="bakery-bnt-yes" onClick={yesBtnHandler}>Uložit</button>
                    <button className="bakery-bnt-no ms-2" onClick={cancelHandler}>Zrušit</button>
                </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-end">
                <button className={maybeBlue ? "bakery-bnt-red" : "bakery-bnt-gray"}
                        disabled={!maybeBlue}
                        onClick={publishBtnHandler}
                >
                    Publikovat
                </button>
            </Col>
        </Row>
    );
});

export default ContentTopMenu;