import exportToHtml from "./exportToHtml";
import React from "react";
import html2pdf from "html-to-pdf-js";

const outputBasketToHtml =(OutputToWeb, sortFields, reservedBaskets)=>{
    return exportToHtml (
        <div>
            <img src="https://bakery-basket1.s3.eu-central-1.amazonaws.com/logo.svg" alt="logo"
                 style={{marginLeft: 20, width: 100, height: 50, objectFit: 'contain'}}
            />
            <OutputToWeb sortFields={sortFields} reservedBaskets={reservedBaskets}/>
        </div>
    )
};

export const openHtmlInNewTab = ({OutputToWeb, sortFields, reservedBaskets}) => {
    const html = outputBasketToHtml(OutputToWeb, sortFields, reservedBaskets)
    const blob = new Blob([html], {type: 'text/html'});
    const url = URL.createObjectURL(blob)
    let ordersTab = window.open(url, "Bakery-Orders");
    if (ordersTab) ordersTab.name = "Bakery-Orders"
    URL.revokeObjectURL(url)
};
export const outputIntoPdf = ({OutputToWeb, sortFields, reservedBaskets,orderNumber}) => {
    const html = outputBasketToHtml(OutputToWeb, sortFields, reservedBaskets)
    html2pdf().from(html).save(`bakeryCart_${orderNumber}`);
};
