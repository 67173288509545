export const empty = () => {}

export const codeFormer = (bp) => {
    const formatWeight = (weight) => {
        if (weight < 10) return "00"
        return weight < 1000 ? '0' + weight.toString()?.at(0) || "00" : weight.toString()?.slice(0, 2) || "00"
    }
    //const formatDigit = (x) => x % 100 < 10 ? '0' + x % 100 : x % 100;

    return (bp.product.dough?.code || 'XX') + bp.type.code + formatWeight(bp.variant.weight) + bp.additive.code
}
