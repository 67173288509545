import React from 'react';
import styles from "./ProductPage.module.css";
import Quality from "../components/qualityComponents/Quality";
const QualityPage = () => {

    return (
        <div className={styles.quaContentArea}>
            <Quality/>
        </div>
    );
};

export default QualityPage;