import React, {useState} from 'react';
import ReservedProducts from "../components/ordersComponents/ReservedProducts";
import styles from "./ProductPage.module.css";
import TopMenu from "../components/ordersComponents/TopMenu";
import OutputSettings from "../components/ordersComponents/output/OutputSettings";
import {empty} from "../utils/utils";
import {observer} from "mobx-react-lite";


const OrdersPage = observer(() => {
    const [show, setShow] = useState(false);
    const [sortFields, setSortFields] = useState({all: true, toHTML: true})

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className={styles.ordersContentArea}>
                <TopMenu yesBtnHandler={handleShow} cancelHandler={empty} publishBtnHandler={empty}/>
                <ReservedProducts sortFields={sortFields} setSortFields={setSortFields}/>
                <OutputSettings show={show} handleClose={handleClose}
                                sortFields={sortFields} setSortFields={setSortFields}/>
        </div>
    );
});

export default OrdersPage;