import React from 'react';
import icon_user from "./img/icon-user.svg"
import icon_address from "./img/icon-adress.svg"
import icon_mail from "./img/icon-mail.svg"
import icon_phone from "./img/icon-phone.svg"
import icon_date from "./img/icon-date.svg"
import icon_gnt from "./img/icon-qnt.svg"
import icon_delivery from "./img/icon-delivery.svg"
import icon_code from "./img/icon-code.svg"
import icon_qlt from "./img/icon-qlt.svg"
import icon_invoice from "./img/icon-invoice.svg"
import icon_not_done from "./img/icon-not-done.svg"
import icon_status from "./img/icon-status.svg"


const DetailsHead = ({row}) => {
    let styles = {
        c11: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.5,
            color: '#2c1902',
            marginLeft: 3
        },
        c22: {
            width: 83,
            height: 40,
            fontSize: 12,
            marginLeft: 40,
            color: '#314b9f',
        },
        c21: {
            width: 83,
            height: 40,
            fontSize: 12,
            marginLeft: 3,
            color: '#2c1902',
        },
        c31: {
            width: 83,
            height: 40,
            fontSize: 12,
            marginLeft: 3,
            color: '#314b9f',
        },
        icon: {
            marginLeft: 15
        }
    }

    const DetailRow = ({rowArr}) => {

        return (
            <div style={{display: 'flex', marginTop: 27}}>
                {rowArr.map((e, i) =>
                    <div key={i} style={{width: `${100 / rowArr.length}%`}}>
                        <span>
                            {e.img ? <img style={styles.icon} src={e.img} alt=""/> : <></>}
                            {e.text ? <span style={e.style}>{e.text}</span> : <></>}
                        </span>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            <DetailRow rowArr={
                [
                    {img: icon_user, text: row.client, style: styles.c11},
                    {img: icon_address, text: "Doručovací adresa", style: styles.c11},
                    {img: icon_address, text: "Fakturační adresa", style: styles.c11}]
            }/>
            <DetailRow rowArr={
                [
                    {img: icon_mail, text: row.email, style: styles.c21},
                    {img: icon_address, text: row.deliveryAddress, style: styles.c21},
                    {}
                ]
            }/>
            <DetailRow rowArr={
                [
                    {img: icon_phone, text: `+${formatLocalNumber(row.phone)}`, style: styles.c31},
                    {},
                    {},
                ]
            }/>
            <div style={{marginTop: 28}}/>
            <div style={{backgroundColor: 'var(--secondary-3)', paddingTop: 1, paddingBottom: 27}}>
                <DetailRow rowArr={
                    [
                        {img: icon_date, text: row.orderDate, style: styles.c31},
                        {img: icon_gnt, text: `${row.amount} ks`, style: styles.c31},
                        {img: icon_delivery, text: "Osobní odběr", style: styles.c31},
                        {img: icon_status, text: "Nezaplaceno", style: styles.c31},
                    ]
                }/>
                <DetailRow rowArr={
                    [
                        {img: icon_code, text: row.orderNumber, style: styles.c31},
                        {img: icon_qlt, text: `${row.price}.00`, style: styles.c31},
                        {img: icon_invoice, text: 'DD00000000', style: styles.c31},
                        {img: icon_not_done, text: row.bpState, style: styles.c31},
                    ]
                }/>
            </div>
        </>
    );
};

const formatLocalNumber = (p) => { // 3232 424 45 -> 323 242 445
    if (p?.length < 2) return p
    const
        pp = (p.match(/\d/g) || []).join(''),
        l = pp.length % 3
    return `${(pp.match(/\d{3}/g) || []).join(" ")}${l ? (" " + pp.slice(-l)) : ""}`
}


export default DetailsHead;