import {$authHost, $host} from "./index";

export const createAllergen = async (allergen) => {
    try {
        const {data} = await $authHost.post('api/allergen', allergen)
        return data
    } catch (err) {
        console.log(err)
        alert("Не выполнено.\n" + err.message)
    }

}

export const updateAllergen = async (allergen) => {
    const {data} = await $authHost.post('api/allergen/update', allergen)
    return data
}

export const fetchAllergens = async () => {
    const {data} = await $host.get('api/allergen',)
    return data
}
export const fetchOneAllergen = async (id) => {
    const {data} = await $host.get('api/allergen/' + id)
    return data
}
