import {useEffect} from "react";
import {fetchReservedBaskets} from "../http/basketAPI";
import {countByCategories} from "../components/ordersComponents/basketProductStates";

export const useLoadBaskets = (basketStore,setLoading) => {
    useEffect(() => {
        fetchReservedBaskets()
            .then(data => {
                basketStore.setStateNamesAndCount(countByCategories(data))
                basketStore.setRBaskets(data)
            })
            .catch(e => alert(`fetchReservedBaskets says... ${e}`))
            .finally(()=>setLoading(false))
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}