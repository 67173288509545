import React, {useContext} from 'react';
import {Table} from "react-bootstrap";
import styles from "./detals.module.css"
import {dateToDDMMYY} from "../../utils/dateTime"
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {bpStateObj} from "./basketProductStates";
import {changeStateByBasketId} from "../../http/basketAPI";
import {openHtmlInNewTab, outputIntoPdf} from "./output/openHtmlInNewTab";
import OutputToWeb from "./output/OutputToWeb";
import DetailsHead from "./DetailsHead";


const DetailsTable = observer(({row}) => {
            const {basketStore} = useContext(Context)
            const basket = basketStore.rBaskets.find(b => b.id === row.basketId)
            if (!basket) return <></>

            const cancelBasket = (basket_id) => {
                const newState = "Canceled"
                const formData = new FormData()
                formData.append('basket_id', basket_id)
                formData.append('state', newState)

                changeStateByBasketId(formData)
                    .then(() => {
                        basketStore.setRBaskets(basketStore.rBaskets
                            .map(b => ({
                                ...b, basket_products: b.basket_products
                                    .map(bp => bp.basket_id === basket_id ? {...bp, state: newState} : bp)
                            })))
                    })
                    .catch(e => console.log("Change BasketProduct State By User error" + e))
            }

            const Output = () => {
                return (
                    <div>
                        <DetailsHead row={row}/>
                        <OutputToWeb sortFields={{}}
                                     reservedBaskets={basketStore.rBaskets.filter(b => b.id === row.basketId)}/>
                    </div>
                )
            }

            const outputBasketToTab = () => {
                openHtmlInNewTab(
                    {
                        OutputToWeb: Output,
                        sortFields: {all: true, toHTML: true},
                        reservedBaskets: basketStore.rBaskets?.filter(b => b.id === row.basketId) || []
                    })
            }

            const outputBasketToPdf = () => {

                outputIntoPdf(
                    {
                        OutputToWeb: Output,
                        sortFields: {all: true, toHTML: true},
                        reservedBaskets: basketStore.rBaskets?.filter(b => b.id === row.basketId) || [],
                        orderNumber:row.orderNumber
                    })
            }

            return (
                <div>
                    <Table borderless>
                        <thead>
                        <tr className={styles.q}>
                            <th colSpan="2">Vyrobek</th>
                            <th>Dat. dodání</th>
                            <th>Odběr</th>
                            <th>Množ.</th>
                            <th>Cena</th>
                            <th colSpan="2">Stav</th>
                        </tr>
                        </thead>

                        <tbody className="">
                        {basket.basket_products.map(bp =>
                            <tr key={bp.id} style={{backgroundColor: bp.id === row.bpId ? 'var(--secondary-10)' : ''}}>
                                <td>{row.productCode}</td>
                                <td>{bp.product.name}</td>
                                <td>{dateToDDMMYY(bp.order_date)}</td>
                                <td>{bp.issue_time}</td>
                                <td>{bp.amount}</td>
                                <td>{bp.price}</td>
                                <td>
                                    <img src={bpStateObj[bp.state]?.icon} alt="dot"/>
                                </td>
                                <td>{bpStateObj[bp.state]?.name}
                                </td>

                            </tr>
                        )}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-between">
                        <div>
                            <button className="bakery-bnt-print"
                                    onClick={outputBasketToTab}> Vytisknout
                            </button>
                            <button className="ms-2 bakery-bnt-to_pdf"
                                    onClick={outputBasketToPdf}
                            > Export do PDF
                            </button>
                        </div>
                        <button className="ms-2 bakery-bnt-no"
                                onClick={() => cancelBasket(row.basketId)}
                        >Zrušit
                        </button>
                    </div>
                </div>
            )
        }
    )
;

export default DetailsTable;