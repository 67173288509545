import React from 'react';
import TextHead from "./TextHead";
import TextBody from "./TextBody";

const OutputToWeb = ({sortFields,reservedBaskets}) => {
    return (
        <div>
            <div style={{backgroundColor: 'rgba(242, 171, 99, 0.05)'}}>
                <table width='100%' border='1' style={{borderCollapse: 'collapse', borderSpacing: '0'}}>
                    <TextHead sortFields={sortFields}/>
                    <TextBody sortFields={sortFields} reservedBaskets={reservedBaskets}/>
                </table>
            </div>
        </div>
    );
};

export default OutputToWeb;