import React, {useContext, useEffect, useState} from 'react';
import {fetchQualities, updateQualities} from "../../http/qualityAPI";
import ResizableTextarea from "../commonComponents/ResizableTextarea";
import {observer} from "mobx-react-lite";
import styles from "../../pages/ProductPage.module.css";
import TopMenu from "../ordersComponents/TopMenu";
import x_close_btn from "../../img/icon-delete.png";
import cs from "../UI/common.module.css";
import {Context} from "../../index";

const Quality = observer(() => {
    const [qualities, setQualities] = useState([])
    const [isSaveClicked, setIsSaveClicked] = useState(false)
    const {basketStore} = useContext(Context)

    const getDataFromDB = () => {
        fetchQualities()
            .then((data) => {
                setQualities(data)
            })
            .catch(e => alert(e))
    }
    useEffect(() => {
        getDataFromDB()
    }, [])


    const changeHandler = (text, key, id) => {
        setQualities(qualities.map(q => q.id === id ? {...q, [key]: text, isChanged: true} : q))
    }
    const saveHandler = () => {
        qualities.find(q => q.isChanged) && setIsSaveClicked(true)
    }

    const cancelHandler = () => {
        qualities.find(i => i.isChanged || i.isDeleted || i.isNew) && getDataFromDB()
        setQualities(qualities.map(q => ({...q, isChanged: false,isDeleted:false})).filter(q => !q.isNew))
        setIsSaveClicked(false)
    }

    const saveItemsToDB = () => {
        if (!isSaveClicked) return;
        const formData = new FormData()
        formData.append('qs', JSON.stringify(qualities.filter(q => q.isChanged)))
        updateQualities(formData).then(() => {
            setQualities(qualities.map(q => ({...q, isChanged: false})))
            setIsSaveClicked(false)
        }).catch(e => alert(e))
    }

    const addQuality = () => {
        setQualities([...qualities, {id: Date.now() % 1000000, name: '', article: '', isNew: true}])
    }
    const deleteItem = (id) => {
        setQualities(qualities.map(q => q.id === id ? {...q, isChanged: true, isDeleted: true} : q))
    }

    return (
        <div className={styles.bodyArea}>
            <TopMenu yesBtnHandler={saveHandler} cancelHandler={cancelHandler}
                     publishBtnHandler={saveItemsToDB}
                     buttons={{btn1: {name: 'Uložit'}, btn2: {name: 'Zrušit'}, btn3: {name: 'Aktualizovat'}}}
                     isSomethingChanged={qualities.find(i => i.isChanged) && isSaveClicked}
            />
            <div style={{height:basketStore.basketXY.h, overflowY: 'scroll'}}>

                {qualities?.filter(q => !q.isDeleted).map(quality =>
                    <div key={quality.id} className="">
                        <div className="mt-4 w-100">
                            <div className="d-flex">
                                <ResizableTextarea
                                    externStyles={{width: "95%",}}
                                    text={quality.name}
                                    setText={(text) => changeHandler(text, "name", quality.id)}/>
                                <img src={x_close_btn} alt="x" className={`${cs.x_close}`}
                                     onClick={() => deleteItem(quality.id)}/>
                            </div>
                            <ResizableTextarea
                                externStyles={{width: "95%", marginTop: "16px"}}
                                text={quality.article}
                                setText={(text) => changeHandler(text, "article", quality.id)}/>
                        </div>
                    </div>
                )}
                <div className="button-text-only my-2" onClick={addQuality}>Přidat položku</div>
                <div style={{height: 200}}/>
            </div>
        </div>
    );
});

export default Quality;