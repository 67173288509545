import React, {useContext, useState} from 'react';
import styles from "./ProductPage.module.css";
import TopMenu from "../components/ordersComponents/TopMenu";
import DirectoryTabMenu from "../components/directoryComponents/DirectoryTabMenu";
import SystemSettings from "../components/directoryComponents/SystemSettings";
import {observer} from "mobx-react-lite";
import {Context, infoStore} from "../index";
import {useLoadSystemData} from "../hooks/useLoadSystemData";
import {Spinner} from "react-bootstrap";
import {updateDough} from "../http/doughAPI";
import {updateType} from "../http/typeAPI";
import {updateAdditives} from "../http/addtiveAPI";
import Firm from "../components/directoryComponents/Firm";
import Users from "../components/directoryComponents/Users";
import {deepEqual} from "../utils/deepEqual";
import {updateFirm} from "../http/firmAPI";

const Directories = observer(() => {
    const {productStore} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [doughs, setDoughs] = useState([])
    const [types, setTypes] = useState([])
    const [additives, setAdditives] = useState([])
    const [isDoughsChanged, setIsDoughsChanged] = useState(false)
    const [isTypesChanged, setIsTypesChanged] = useState(false)
    const [isAdditivesChanged, setIsAdditivesChanged] = useState(false)
    const [isFirmChanged, setIsFirmChanged] = useState(false)

    const [tabMenuIndex, setTabMenuIndex] = useState(2);
    const tabMenu = [
        {component: Firm, nameCz: 'Firma'},
        {component: Users, nameCz: 'Správa uživatelů'},
        {component: SystemSettings, nameCz: 'Nastavení systému'}]
    const tabMenuItems = tabMenu.map(e => e.nameCz)
    const [firm, setFirm] = useState(infoStore.firm);


    useLoadSystemData({
        setLoading, productStore, setDoughs, setTypes, setAdditives
    })
    if (loading) return <Spinner animation={"grow"}/>

    const emptyStrFilter = (name, code) => name !== '' && code !== '';

    const saveHandler = () => {
        setIsDoughsChanged(doughs.find(e => e.isUpdated) || doughs.length !== productStore.doughs.length)
        setIsTypesChanged(types.find(e => e.isUpdated) || types.length !== productStore.types.length)
        setIsAdditivesChanged(additives.find(e => e.isUpdated) || additives.length !== productStore.additives.length)

        productStore.setDoughs(doughs.filter(e => emptyStrFilter(e.name, e.code)))
        productStore.setTypes(types.filter(e => emptyStrFilter(e.name, e.code)))
        productStore.setAdditives(additives.filter(e => emptyStrFilter(e.name, e.code)))
        setDoughs(productStore.doughs.filter(e => emptyStrFilter(e.name, e.code)))
        setTypes(productStore.types.filter(e => emptyStrFilter(e.name, e.code)))
        setAdditives(productStore.additives.filter(e => emptyStrFilter(e.name, e.code)))
        setIsFirmChanged(!deepEqual(firm, infoStore.firm))
    }
    const cancelHandler = () => {
        setDoughs(productStore.doughs)
        setTypes(productStore.types)
        setAdditives(productStore.additives)
        setFirm(infoStore.firm)
    }

    const saveSettingsToDB = () => {
        const fdDough = new FormData()
        const fdType = new FormData()
        const fdAdditive = new FormData()
        fdDough.append('doughs', JSON.stringify(productStore.doughs))
        fdType.append('types', JSON.stringify(productStore.types))
        fdAdditive.append('additives', JSON.stringify(productStore.additives))

        const fdFirm = new FormData()
        fdFirm.append('newFirm', JSON.stringify(firm))

        isDoughsChanged && updateDough(fdDough)
            .then(() => {
                    setDoughs(doughs.map(e => e.isUpdate ? {...e, isUpdate: false} : e))
                    setIsDoughsChanged(false)
                }
            ).then()
            .catch(e => {
                alert("updateDough says: " + e)
            })


        isTypesChanged && updateType(fdType)
            .then(() => {
                    setTypes(types.map(e => e.isUpdate ? {...e, isUpdate: false} : e))
                    setIsTypesChanged(false)

                }
            )
            .catch(e => {
                alert("updateTypes says: " + e)
            })

        isAdditivesChanged && updateAdditives(fdAdditive)
            .then(() => {
                    setAdditives(additives.map(e => e.isUpdate ? {...e, isUpdate: false} : e))
                    setIsAdditivesChanged(false)
                }
            )
            .catch(e => {
                alert("updateAdditives says: " + e)
            })


        isFirmChanged && updateFirm(fdFirm)
            .then((data) => {
                infoStore.setFirm(data)
                setIsFirmChanged(false)
            })
            .catch(e => {
                alert("updateFirm says >>>>: " + e)
            })

        window.location.reload()
    }

    return (
        <div className={styles.dirContentArea}>
            <div className={styles.bodyArea}>
                <TopMenu yesBtnHandler={saveHandler} cancelHandler={cancelHandler}
                         publishBtnHandler={saveSettingsToDB}
                         buttons={{btn1: {name: 'Uložit'}, btn2: {name: 'Zrušit'}, btn3: {name: 'Aktualizovat'}}}
                         isSomethingChanged={isDoughsChanged || isTypesChanged || isAdditivesChanged || isFirmChanged}
                />
                <div style={{backgroundColor: 'var(--white'}}>
                    <DirectoryTabMenu menuIndex={tabMenuIndex} setMenuIndex={setTabMenuIndex}
                                      menuItems={tabMenuItems}/>
                </div>
                <div className="w-100">
                    {tabMenu[tabMenuIndex].component === Firm &&
                        <Firm firm={firm} setFirm={setFirm}/>}
                    {tabMenu[tabMenuIndex].component === Users &&
                        <Users/>}
                    {tabMenu[tabMenuIndex].component === SystemSettings &&
                        <SystemSettings doughs={doughs} setDoughs={setDoughs}
                                        types={types} setTypes={setTypes}
                                        additives={additives} setAdditives={setAdditives}/>
                    }
                </div>
            </div>
        </div>
    )
});

export default Directories;
