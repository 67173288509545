import React from 'react';
import {Form} from "react-bootstrap";
import {daysOfWeekCZ} from "../../utils/daysOfWeek";


const SelectDays = ({bitmask, setBitmask}) => {
    return (
        <div className="myCheckBoxContainer">
            {daysOfWeekCZ?.map((day, i) =>
                <div key={day.name} className="myCheckBox">
                    <span className={i < 5 ? "myCheckBoxText" : "myCheckBoxText1"}>{day.name}</span>
                    <Form.Check
                        type="checkbox"
                        checked={bitmask & (1 << i)}
                        onChange={() => setBitmask(bitmask ^ (1 << i))}
                    />
                </div>
            )}
        </div>
    );
};

export default SelectDays;
