import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

//https://axios-http.com/docs/interceptors
// Add a response interceptor
$host.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log({...error})

    return Promise.reject(error?.response?.data.message);
});
$authHost.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(`interceptors.response says:${error}`)
    return Promise.reject(error.response.data.message);
});


export {
    $host,
    $authHost
}
