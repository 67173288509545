
/**
 * Class FormattedOrders builds object _formattedOrders for output orders.
 *
 * _formattedOrders
 * returns
 * {
 *   title: "Výběr sestavy k exportu",
 *   all: {
 *     "name: "vše rezervací",
 *     isSelected": true,
 *     setI: setSortOptions, // callBack function
 *     },
 *   orderDate: {
 *     name: "dle data dodání",
 *     column: 1
 *     isSelected": true,
 *     setI: setSortOptions,
 *   },
 *   orderNumber: {
 *     name: "dle čísla rezervace",
 *     column: 2
 *     isSelected": true,
 *     setI: setSortOptions,
 *   },
 *   ...
 *   }
 *
 */

export class FormattedOrders {
    constructor(sortFields, setSortFields) {
        this.sortFields = sortFields
        this.setSortFields = setSortFields
        this.categories = [
            {name: "orderDate", nameCz: "dle data dodání"},
            {name: "orderNumber", nameCz: "dle čísla rezervace"},
            {name: "createdAt", nameCz: "dle data rezervace"},
            {name: "productCode", nameCz: "dle čísla vyrobku"},
            {name: "client", nameCz: "dle zákazníka"},
            {name: "bpState", nameCz: "dle stavu"},
        ]
        this.formats = [
            {name: "toPDF", nameCz: "do PDF"},
            {name: "toXLS", nameCz: "do XLS"},
            {name: "toHTML", nameCz: "do HTML"}
        ]
        this.sortCategories = this.categories.map(c => c.name)
        this.formatCategories = this.formats.map(f => f.name)
    }

    get formattingCategories() {
        return this.formatCategories
    }

    setSortOptions(oldSortCategories, sortCategories, sortFields, category) {
        const setSortTo = (b) => {
            oldSortCategories.forEach(c => sortFields[c] = b)
        }
        if (category === "all") {
            !oldSortCategories.all && sortFields.all && setSortTo(false)
        } else if (sortCategories.find(c => c === category)) {
            sortFields.all = false;
        }

        if (this.formatCategories.find(f => f === category)) {
            this.formatCategories.forEach(e => sortFields[e] = false)
            sortFields[category] = true
        }
        this.setSortFields(sortFields)
    }

    _formattedOrders = () => {
        const f = (key) => () =>
            this.setSortOptions(
                this.sortFields, this.sortCategories, {...this.sortFields, [key]: !this.sortFields[key]}, key
            )

        const ff = (key) => ({isSelected: this.sortFields[key], setI: f(key)})

        const obj0 = {
            title: "Výběr sestavy k exportu",
            all: {name: "vše rezervací", isSelected: this.sortFields?.all, setIS: f("all")}
        }
        const obj1 = this.categories?.reduce((sum, c, i) =>
            Object.assign(sum, {[c.name]: {name: c.nameCz, ...ff(c.name), column: (i % 2 + 1)}}), obj0) || obj0

        return this.formats?.reduce((sum, c) =>
            Object.assign(sum, {[c.name]: {name: c.nameCz, ...ff(c.name), column: 0}}), obj1
        ) || obj1
    };

    get formattedOrders() {
        return this._formattedOrders()
    }
}
