import React from 'react';
import {Form} from "react-bootstrap";
import SelectDays from "../commonComponents/SelectDays";

const ProductScreen2 = ({product,setProduct}) => {

    const isReserved = product?.reserve || false

    const setProductKey = (key, value) => {
        setProduct({...product, [key]: value})
    }
    return (
        <Form className="mt-4 ms-3">
            <Form.Group className="mb-4">
                <Form.Label style={{fontSize:14}}>Zobrazit vyrobek na webových stránkách:</Form.Label>
                <SelectDays bitmask={product?.days} setBitmask={v=>setProductKey("days", v)}/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label style={{fontSize:14}}>Možnost online rezervace vyrobku:</Form.Label>
                <div className="myCheckBox">
                    <Form.Check
                        type="checkbox"
                        checked={isReserved}
                        onChange={()=>setProductKey("reserve", !isReserved)}
                    />
                </div>
            </Form.Group>
        </Form>
    );
};

export default ProductScreen2;