import React from 'react';
import styles from "./InputTextLi.module.css";
import cs from '../UI/common.module.css'
import x_close_btn from "../../img/icon-delete.png";
import {makeId} from "../../utils/dateTime";
import MyInputNumber from "../UI/MyInputNumber";
import MyInputText from "../UI/MyInputText";
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";

const InputTextLi = ({
                         liTexts, setLiTexts, code, price, rectStyle, codeStyle, priceStyle, textStyle,
                         mainName, codeName, priceName

                     }) => {

    const addIt = () => setLiTexts([...liTexts, {
        id: makeId(),
        name: '',
        isUpdated: true
    }])

    const delIt = (id) => liTexts.length > 1 && setLiTexts(liTexts.filter(l => l.id !== id))

    const setIt = (text, id) => {
        setLiTexts(liTexts.map(l => l.id === id ? {...l, name: text, isUpdated: true} : l))
    }
    const codeInputHandler = (value, id) => {
        if (value.length > 2) return;
        if (value.length === 2 && liTexts.find(t => t[code] === value)) return
        setLiTexts(liTexts.map(l => l.id === id ? {...l, [code]: value, isUpdated: true} : l))
    }
    const priceInputHandler = (value, id) => {
        if (value.length > 2) return;
        if (value.length === 2 && liTexts.find(t => t[price] === value)) return
        setLiTexts(liTexts.map(l => l.id === id ? {...l, [price]: value, isUpdated: true} : l))
    }

    return (
        <div className="mt-5">
            <Row>
                <Col xl={4} md={6} sm={12} className="d-flex">
                    <div className={cs.circle}/>
                    <span>{mainName}</span>
                </Col>
                {code ?
                    <Col xl={2} md={3} sm={6}>
                        <div className="">{codeName}</div>
                    </Col> : <Col></Col>
                }
                {price ?
                    <Col xl={2} md={3} sm={6}>
                        <div className="">{priceName}</div>
                    </Col> : <Col></Col>
                }
            </Row>
            {liTexts?.map(t =>
                <Row key={t.id} className={`mt-1 ${rectStyle}`}>
                    <Col xl={4} md={6} sm={12} style={{display: 'flex', whiteSpace: "nowrap"}}>
                        <MyInputText
                            className={`${textStyle}`}
                            value={t.name}
                            onChange={value => setIt(value, t.id)}
                        />
                        <img src={x_close_btn} alt="x" className={`${cs.x_close}`}
                             onClick={() => delIt(t.id)}/>
                    </Col>
                    <Col xl={2} md={3} sm={6}>
                        {code ?
                            <MyInputText
                                className={`${codeStyle}`}
                                value={t[code]}
                                onChange={value => codeInputHandler(value, t.id)}
                            /> : <></>
                        }
                    </Col>

                    <Col xl={2} md={3} sm={6} className="d-flex">
                        {price &&
                            <MyInputNumber
                                className={`${priceStyle}`}
                                value={t[price]}
                                onChange={value => value < 1000000 && priceInputHandler(value, t.id)}
                            />
                        }
                        {Number(t[price]) ?
                            <div className={styles.unit} style={{left: -65 + t[price].toString().length * 8}}>
                                Kč
                            </div> :
                            <div className={styles.unit}></div>
                        }
                    </Col>
                </Row>
            )}
            <div className="button-text-only mt-1" onClick={addIt}>Přidat položku</div>
        </div>
    )
};
export default InputTextLi;