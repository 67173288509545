export const dateToTime = (date_in) => {
    let date = new Date(date_in)
    const hours = date.getHours()
    let sHours = hours < 10 ? `0${hours}` : `${hours}`
    const minutes = date.getMinutes()
    let sMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    return sHours + ":" + sMinutes
}
export const timeToDate = (time) => {
    let date = new Date(0)
    date.setHours(Number(time[0]+time[1]),Number(time[3]+time[4]),0,0)
    return date
};
export const timeToDate1 = (dateString,time) => {
    let date = new Date(dateString)
    date.setHours(Number(time[0]+time[1]),Number(time[3]+time[4]),0,0)
    return date
};

export function wait(ms) {
    let d = new Date();
    let d2 = null;
    do {
        d2 = new Date();
    }
    while (d2 - d < ms);
}

export const dateTime = (dateString) => {
    const date = new Date(Date.parse(dateString))
    return (date.getDate().toString() + "." +
        (date.getMonth()+1).toString() + "." + date.getFullYear().toString() + " " +
        date.getHours().toString() + ":" + date.getHours().toString())

}

export const dateToDDMMYY = (dateString) =>{
    const date = new Date(Date.parse(dateString))
    const month = date.getMonth()+1
    let d = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`
    let m = month > 9 ? `${month}` : `0${month}`
    let y = date.getFullYear()
    return d + '.' + m + '.' + y
}

/**
 * convert date format dd.mm.yyyy to yyyy.mm.dd*/
export const dmyToYmd = (ddmmyyyy) => {
    let xs = ddmmyyyy.split(".")
    return xs? Number(xs.at(2)+xs.at(1)+xs.at(0)) : undefined
}

export const dateCompare = (date1,date2) => {
    let d1=new Date(new Date(date1).setHours(0,0,0,0))
    let d2=new Date(new Date(date2).setHours(0,0,0,0))

    return d1-d2

}
export const makeId = ()=> {
    return Number(Date.now()) % 1e9;
}
