import {$authHost, $host} from "./index";

export const createProduct = async (product) => {
    const {data} = await $authHost.post('api/product', product)
    return data
}

export const fetchProducts = async () => {
    const {data} = await $host.get('api/product')
    return data
}
export const fetchBigProducts = async () => {
    const {data} = await $host.get('api/product/bigAll')
    return data
}

export const fetchOneProduct = async (id) => {
    if(id) {
        const {data} = await $host.get('api/product/' + id) // productController.getOne
        return data
    }
    else return null
}
export const fetchBigOneProduct = async (id) => {
    if(id) {
        const {data} = await $host.get('api/product/bigOne/' + id)
        return data
    }
    else return null
}

export const updateProduct = async (product) => {
        const {data} = await $authHost.post('api/product/update/',product)
    return data
}

export const updateProductNumbers = async (products) => {
        const {data} = await $authHost.post('api/product/update/numbers/',products)
        return data
}

export const deleteProduct = async (id) => {
    if(id) {
        const {data} = await $authHost.post('api/product/delete/' + id)
        return data
    }
    else return null
}

export const previewImg = async (dataIn) => {
    const {data} = await $authHost.post('api/product/previewImg/',dataIn)
    return data
}
