import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import MyInputNumber from "../components/UI/MyInputNumber";
import styles from "./List.module.css";
import ListButtons from "../components/productListComponents/ListButtons";
import ContentTopMenu from "../components/productListComponents/ContentTopMenu";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../utils/consts";
import {observer} from "mobx-react-lite";

const List = observer(({
                  products, delProduct, changeNumberHandler, blurNumberHandler, editProductHandler, setVisibility,
                           yesBtnHandlerList, cancelHandler, publishBtnHandler
              }) => {
    const {user} = useContext(Context)
    const {basketStore} = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user?.isAuth) {
            navigate(LOGIN_ROUTE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isAuth])

    const cl_warning = `${styles.productList1} ${styles.warning}`
    const cl_normal = `${styles.productList1}`
    return (
        <div className="px-2">
            <ContentTopMenu yesBtnHandler={yesBtnHandlerList} cancelHandler={cancelHandler} publishBtnHandler={publishBtnHandler}/>

            <div
                style={{marginTop:0,height:basketStore.basketXY.h-160,overflowY:'scroll'}}
            >
            {products.map((product, index) =>
                <Row key={product.id} className={index % 2 !== 0 ? styles.evenStripe : styles.oddStripe}>
                    <Col sm={8} className="px-0 mx-m overflow-hidden">
                        <div className={`${styles.numberAndName}`}>
                            {product.status === 'ShowInTheShop' ?
                                <MyInputNumber value={product.number}
                                               onChange={(val) => changeNumberHandler(val, product.id)}
                                               onBlur={(val) => blurNumberHandler(val, product.id)}
                                               className={product.warning ? cl_warning : cl_normal}
                                />
                                :
                                <span className={cl_normal} style={{opacity: 0.5}}>{product.number}</span>

                            }
                            <span className={styles.productName}
                                  style={{opacity: product.status === 'ShowInTheShop' ? '1' : '0.5'}}>
                                         {product.name}
                                     </span>
                        </div>
                    </Col>
                    <Col sm={4} className="d-flex flex-wrap align-content-center">
                        <ListButtons product={product}
                                     delProduct={delProduct}
                                     editProductHandler={editProductHandler}
                                     setVisibility={setVisibility}
                        />
                    </Col>
                </Row>
            )}
            </div>
        </div>
    );
});

export default List;