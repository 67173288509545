import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {dateCompare} from "../utils/dateTime";
import {useLoadBaskets} from "../hooks/useLoadBaskets";
import {Spinner} from "react-bootstrap";
import {codeFormer} from "../utils/utils";
import iconClose from "../img/icon-delete@3x.png"
import {LIST_ROUTE} from "../utils/consts";
import {useNavigate} from "react-router-dom";
import Timer from "../components/Timer";


const DashBoard = observer(() => {
    const {basketStore} = useContext(Context)
    const {productionStore} = useContext(Context)
    const [loading, setLoading] = useState(true)

    const today = new Date()
    const navigate = useNavigate()


    useLoadBaskets(basketStore, setLoading)
    if (loading) return <Spinner animation={"grow"}/>

    return (
        <div style={{
            position: "fixed", top: 0, left: 0, zIndex: 2, opacity: 1,
            width: "100%", height: basketStore.basketXY.h,overflow: "auto", background: 'white', border: "solid 5px red "
        }}>
            <div className="d-flex w-100 justify-content-end">
                <div className="d-flex align-items-center">
                    <img src={iconClose} alt="icon-close" style={{width: "50px", height: "50px", cursor: "pointer"}}
                         onClick={() => navigate(LIST_ROUTE)}/>
                </div>
            </div>

            <div className="d-flex justify-content-between mt-5">

                <div className="d-flex justify-content-center me-5 w-50 fs-2">
                    {basketStore.rBaskets.map(b =>
                        b.basket_products
                            .filter(p => dateCompare(new Date(p.order_date), today) === 0)
                            .map(bp =>
                                <div key={bp.id} className="d-flex align-items-center">
                                    <div className="mx-3">{codeFormer(bp)}</div>
                                    <div className="mx-3">{bp.amount}</div>
                                    <div
                                        className="mx-3">{productionStore.productions.find(p => p.id === bp.production_id)?.amount || 0}</div>
                                </div>)
                    )}
                </div>
                <div  className="d-flex justify-content-center me-5 w-50 fs-2">
                    <Timer/>
                </div>
            </div>
        </div>
    );
});

export default DashBoard;