import {ButtonGroup, Dropdown} from "react-bootstrap";
import x_close_btn from "../../img/icon-delete.png";
import icon_list from "../../img/icon-list.png";
import styles from "./SelectTypes.module.css";
import {MyDropdownToggle} from "../UI/MyDropdownToggle";
import {makeId} from "../../utils/dateTime";
import React from "react";
import cl from "../UI/myInputNumber.module.css";

const SelectTypes2 = ({types, selectedTypes, variants, product, setProduct, label}) => {

    //console.log('SelectTypes2 selectedTypes:' + JSON.stringify(selectedTypes, null, 2))
    //console.log('SelectTypes2 variants:' + JSON.stringify(variants, null, 2))

    //const setTypesVariants = (types, variants) => setProduct({...product, types, variants})

    const additives = () => [{
        id: makeId(), name: "bez posypu",
        mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, sun: 0,
        additive_id: 1,
    }];

    const addTypeId = () => {

        const tmp_id = makeId()

        const tps = [...selectedTypes?.filter(t => !t.isEmptyRow),
            {id: tmp_id, name: "", type_id: tmp_id, isEmptyRow: true}]

        const vts = [...variants.filter(v => !v.isEmptyRow),
            {id: tmp_id, weight: "", isEmptyRow: true,}
        ]
            .map(v => (v.id === tmp_id ? {
                    ...v,
                    variant_types: v.variant_types ?
                        [...v.variant_types, {id: tmp_id, type_id: tmp_id, isEmptyRow: true, additives: additives()}] :
                        [{id: tmp_id, type_id: tmp_id, isEmptyRow: true, additives: additives()}]
                } : v
            ))
        //debugger

        setProduct({...product, types: tps, variants: vts})
    }

    const removeSelectedType = (sType, variant) => {
        const vFiltered = variants.filter(v => v.variant_types.find(f => f.type_id === sType.type_id))

        const tps = vFiltered?.length < 2 ? selectedTypes.filter(t => t.type_id !== sType.type_id) : selectedTypes
        let vrs = variants.map(v => v.id === variant.id ? {
                ...v,
                variant_types: v.variant_types.filter(f => f.type_id !== sType.type_id)
            } : v
        )
            .filter(vv => vv.variant_types.length > 0)

        setProduct({...product, types: tps, variants: vrs})
        //debugger
    }


    const changeSelectedType = (type, variant) => {

        const isTypeInSelectedTypes = selectedTypes.some(st => !st.isEmptyRow && st.type_id === type.id);
        const isTypeInVariants = variants.some(v => !v.isEmptyRow && v.variant_types.some(vt => vt.type_id === type.id));
        const tps = isTypeInSelectedTypes
            ? selectedTypes.filter(({isEmptyRow}) => !isEmptyRow)
            : [...selectedTypes, {
                id: makeId(),
                type_id: type.id,
                product_id: product.id,
                price: 0,
                name: type.name,
                isEmptyRow: false
            }];
        const vrs = variant.isEmptyRow
            ? variants.map(v => v.id === variant.id
                ? {...v, variant_types: v.variant_types.map(vt => ({...vt, type_id: type.id, isEmptyRow: false}))}
                : v
            )
            : isTypeInVariants
                ? variants
                : variants.map(v => ({
                    ...v,
                    variant_types: v.variant_types ? [...v.variant_types, {type_id: type.id}] : [{
                        type_id: type.id,
                        isEmptyRow: false
                    }]
                }));


        setProduct({...product, types: tps, variants: vrs})
        //debugger
    }

    const changeVariant = (selectedType, variant, value) => {
        if (value > 1000000 || selectedType.name === '') return;

        const isWeightInVariants = variants.some(v => v.weight === value && v.variant_types.some(vt => vt.type_id === selectedType.type_id));
        const warningStyle = isWeightInVariants ? {border: "solid 3px var(--warning)"} : undefined;

        setProduct({
            ...product,
            variants: variants.map(v => {
                if (v.id === variant.id) {
                    return {...v, weight: value, isEmptyRow: false, isNewRow: true};
                } else {
                    const isSameVariant = v.weight === value;
                    return {...v, warningStyle: isSameVariant ? warningStyle : undefined};
                }
        })
        });
    }
    const onBlurVariant = (sType, variant, value) => {
        if (variants?.some(v => v.weight === value && v.id !== variant.id && v.variant_types.some(vt => vt.type_id === sType.type_id))) {
            const updatedVariants = variants.filter(v => v.id !== variant.id).map(v => ({
                ...v,
                warningStyle: undefined
            }));
            setProduct({...product, variants: updatedVariants});
        }
    }

    const weight = (variant) => variant?.weight || ''

    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}
            {selectedTypes.map(sType =>
                <div key={sType.id}>
                    {variants.filter(v => v.variant_types?.find(vt => vt.type_id === sType.type_id))
                        .map(variant =>
                            <div key={variant.id} className={styles.selectTypeContainer}>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle as={MyDropdownToggle}
                                    >
                                        <div className={`${styles.selectType}  d-flex`}>
                                            <span>{sType.name}</span>
                                            <img src={icon_list} alt={">"}/>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {types.map(type =>
                                            <Dropdown.Item
                                                key={type.id}
                                                onClick={() => changeSelectedType(type, variant, sType.id)}
                                            >{type.name}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <input type="text"
                                       className={cl.rectangle + ' ' + styles.myInputNumber}
                                       style={variant.warningStyle}
                                       value={variant?.weight || ''}
                                       onChange={e =>
                                           changeVariant(sType, variant, parseInt(e.target.value) || 0)}
                                       onBlur={e =>
                                           onBlurVariant(sType, variant, parseInt(e.target.value) || 0)}
                                />
                                {variant?.weight && variant.weight !== "" ?
                                    <div className={styles.unit}
                                         style={{left: -64 + weight(variant).toString().length * 8}}>
                                        g
                                    </div> :
                                    <div className={styles.unit}></div>
                                }
                                <img src={x_close_btn} className={styles.x_close} alt={">"}
                                     onClick={() => removeSelectedType(sType, variant)}/>
                            </div>
                        )
                    }
                </div>
            )}

            <div className="button-text-only"
                 onClick={addTypeId}>
                Přidat položku
            </div>


        </div>
    );
};

export default SelectTypes2;
