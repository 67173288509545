import React from 'react';
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {MyDropdownToggle} from "../UI/MyDropdownToggle";
import styles from "./InputDropdown.module.css";
import icon_list from "../../img/icon-list.png";


/**
 *
 * @param bpStateObj -
 * {
 *     Reserved:    {name: "Nevyřízená",...},
 *     Issued:      {name: "Vyřízená",},
 *     Canceled:    {name: "Stornováná",,
 *     Processing:  {name: "Vyřízuje se", icon: icon_status4, borderColor: "var(--secondary),...,state:'...'"},
 * }
 * @param titleStyle - style of dropdown element
 * @param borderColor - can change this
 * @param state - current value
 * @param setState -
 * @param selectedKey
 * @returns {JSX.Element}
 * @constructor
 */
const InputDropdown = ({bpStateObj, titleStyle, borderColor, state, setState, label}) => {
    return (
        <div className={styles.container}>
            {label && <div className={styles.label}>{label}</div>}
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={MyDropdownToggle}
                >
                    <div className={`${styles.selectType} ${titleStyle}`} style={{borderColor}}>
                        <span style={{fontSize: 12, marginTop: 4}}>{state}</span>
                        <img src={icon_list} alt={"V"}/>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{fontSize: 12, minWidth: 130, marginLeft: 10}}>
                    {Object.entries(bpStateObj).map(([key, value]) =>
                        <Dropdown.Item key={value.name} onClick={() => setState(key)}>
                            {value.name}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default InputDropdown;