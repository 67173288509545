import React from 'react';
import cl from "./myInputNumber.module.css";

const MyInputText = React.forwardRef(({value, onChange, onBlur, className, style, placeholder, lable: label}, ref) => {
    return (
        <div>
            {label && <div style={{fontSize:12,marginBottom:5}}>{label}</div>}
            <input ref={ref}
                   className={cl.rectangle + ' ' + className}
                   style={style}
                   placeholder={placeholder}
                   value={value || ""}
                   onChange={e => onChange && onChange(e.target.value)}
                   onBlur={e => onBlur && onBlur(e.target.value)}
            />
        </div>
    );
});

export default MyInputText;
