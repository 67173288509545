import React from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const DirectoryTabMenu = ({menuIndex, setMenuIndex,menuItems }) => {

    return (
        <Tabs activeKey={menuIndex}
              onSelect={setMenuIndex}
              className="myTabsClass"
        >
            {menuItems.map((x, i) =>
                <Tab key={i} eventKey={i} title={x}>
                </Tab>
            )}
        </Tabs>
    );
};

export default DirectoryTabMenu;