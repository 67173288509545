import React from 'react';
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import icon_actual from "./img/icon-actual.svg"


const TopMenu = ({yesBtnHandler, cancelHandler, publishBtnHandler,buttons,isSomethingChanged}) => {
    const {btn1,btn2,btn3} = buttons || {}
    return (
        <Row>
            <Col sm={8} className="">
                <div className="d-flex flex-nowrap">
                    <button className="bakery-bnt-yes" onClick={yesBtnHandler}>{btn1?.name || 'Vytistnout'}
                    </button>
                    <button className="bakery-bnt-no ms-2 bg-primary" onClick={cancelHandler}>{btn2?.name || 'Export'}
                    </button>
                </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-end ">
                <button className={isSomethingChanged ? "bakery-bnt-red" : "bakery-bnt-gray"}
                        onClick={()=>isSomethingChanged? publishBtnHandler():{}}
                >
                    <img src={icon_actual} alt="" className="me-1"/>
                    {btn3?.name || 'Aktualizovat'}
                </button>
            </Col>

        </Row>
    );
};

export default TopMenu;