import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import React, {useRef, useState} from 'react'
import styles from './InputImg.module.css'
import {imgPreview} from "./imgPreview";
import {FaCheck} from 'react-icons/fa';
import {Spinner} from "react-bootstrap";


const ImageCropper = ({imageDimensions, crop, setCrop,  imgSrc, onFileCropped}) => {

    const imgRef = useRef(null)
    const {imgWidth, imgHeight} = imageDimensions
    const [isImageLoading, setIsImageLoading] = useState(false)

    const newImgHandler = () => {
        setIsImageLoading(true)
        imgPreview(imgRef.current, crop)
            .then(data => {
                onFileCropped(data)
            })
            .catch(err => console.log(err))
            .finally(() => setIsImageLoading(false))
    }

    return (
        <div>
            {Boolean(imgSrc) && (
                <div className="d-inline-flex">
                    <ReactCrop
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        locked={true}
                    >
                        <img
                            ref={imgRef}
                            className={styles.imageForCrop}
                            alt="Crop me"
                            src={imgSrc}
                            style={{width: imgWidth, height: imgHeight}}
                        />
                    </ReactCrop>
                    {isImageLoading ?
                        <Spinner animation="border" variant="primary" className={styles.spinner}/>
                        :
                        <div className={styles.checkOk} onClick={newImgHandler}>
                            <FaCheck className="fs-6"/>
                        </div>
                    }

                </div>
            )}
            <div>
            </div>
        </div>
    )
};

export default ImageCropper;
