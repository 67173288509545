import React from "react";

export const MyDropdownToggle = React.forwardRef(({children,onClick,className,style}, ref) =>
    (
    <div
        ref={ref}
        className={className}
        style={style}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));

//&#x25bc; - это стрелочка вниз, я ее убрал
