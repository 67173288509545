import React, {useContext} from 'react';
import styles from "./Firm.module.css";
import MyInputText from "../UI/MyInputText";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const Firm = observer(({firm, setFirm}) => {
    const {basketStore} = useContext(Context)
    return (
        <div style={{height:basketStore.basketXY.h*0.8,overflowY:'scroll'}}>

            <div className={styles.cont1}>
                <MyInputText className={styles.hdr6} value={firm.title}
                             onChange={(e) => {
                                 setFirm({...firm, title: e})
                             }}
                />
                <div className={styles.lab7}>Obchodní jmeno (název společnosti)</div>
                <MyInputText className={styles.inp1} placeholder="Název firmy"
                             value={firm.name}
                             onChange={(e) => {
                                 setFirm({...firm, name: e})
                             }}
                />

                <div style={{marginTop: 20, display: "flex"}}>
                    <div style={{width: 231}}>Ulice a č.o.)</div>
                    <div style={{width: 55, marginLeft: 5}}>č.p.</div>
                </div>
                <div style={{marginTop: 6, display: "flex", height: 32}}>
                    <MyInputText className={styles.inp1} style={{width: 231}}
                                 value={firm.address?.street}
                                 onChange={(e) => {
                                     setFirm({...firm, address: {...firm.address, street: e}})
                                 }}
                    />
                    <MyInputText className={styles.inp1} style={{width: 55, marginLeft: 5}}/>
                </div>

                <div style={{marginTop: 20, display: "flex"}}>
                    <div style={{width: 85}}>PSČ</div>
                    <div style={{width: 200, marginLeft: 5}}>Město</div>
                </div>
                <div style={{marginTop: 6, display: "flex", height: 32}}>
                    <MyInputText className={styles.inp1} style={{width: 85}}
                                 value={firm.address?.zip}
                                 onChange={(e) => {
                                     setFirm({...firm, address: {...firm.address, zip: e}})
                                 }}
                    />
                    <MyInputText className={styles.inp1} style={{width: 200, marginLeft: 5}}
                                 value={firm?.address?.city}
                                 onChange={(e) => {
                                     setFirm({...firm, address: {...firm.address, city: e}})
                                 }}
                    />
                </div>
            </div>

            <div className={styles.cont2}>
                <MyInputText className={styles.hdr6} value={firm.branches?.at(0)?.title}
                             onChange={(e) => {
                                 setFirm({...firm, branches: [{...firm.branches?.at(0), title: e}]})
                             }}
                />
                <div className={styles.lab7}>Název prodejny</div>
                <MyInputText className={styles.inp1}
                             value={firm.branches?.at(0)?.name}
                             onChange={(e) => {
                                 setFirm({...firm, branches: [{...firm.branches?.at(0), name: e}]})
                             }}
                />

                <div style={{marginTop: 20, display: "flex"}}>
                    <div style={{width: 231}}>Ulice a č.o.)</div>
                    <div style={{width: 55, marginLeft: 5}}>č.p.</div>
                </div>
                <div style={{marginTop: 6, display: "flex", height: 32}}>
                    <MyInputText className={styles.inp1} style={{width: 231}}
                                 value={firm.branches?.at(0)?.address.street}
                                 onChange={(e) => {
                                     setFirm({
                                         ...firm, branches: [{
                                             ...firm.branches?.at(0),
                                             address: {...firm.branches?.at(0)?.address, street: e}
                                         }]
                                     })
                                 }}
                    />
                    <MyInputText className={styles.inp1} style={{width: 55, marginLeft: 5}}/>
                </div>

                <div style={{marginTop: 20, display: "flex"}}>
                    <div style={{width: 85}}>PSČ</div>
                    <div style={{width: 200, marginLeft: 5}}>Město</div>
                </div>
                <div style={{marginTop: 6, display: "flex", height: 32}}>
                    <MyInputText className={styles.inp1} style={{width: 85}}
                                 value={firm.branches?.at(0)?.address.zip}
                                 onChange={(e) => {
                                     setFirm({
                                         ...firm, branches: [{
                                             ...firm.branches?.at(0),
                                             address: {...firm.branches?.at(0)?.address, zip: e}
                                         }]
                                     })
                                 }}
                    />
                    <MyInputText className={styles.inp1} style={{width: 200, marginLeft: 5}}
                                 value={firm.branches?.at(0)?.address.city}
                                 onChange={(e) => {
                                     setFirm({
                                         ...firm, branches: [{
                                             ...firm.branches?.at(0),
                                             address: {...firm.branches?.at(0)?.address, city: e}
                                         }]
                                     })
                                 }}
                    />
                </div>
            </div>
        </div>
    );
});

export default Firm;