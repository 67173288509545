import React from 'react';
import {TiArrowUnsorted, TiArrowUpThick, TiArrowDownThick} from "react-icons/ti";
import styles from "./reservedProducts.module.css"
import {sortBy} from "../../utils/sorti";

const TableHead = ({sortFields, setSortFields}) => {

    const SortComp = ({sortKey}) => {

        //console.log(sortKey+"  "+sortFields[sortKey])
        let Icon = TiArrowUnsorted;

        switch (sortFields[sortKey]) {
            case "Asc":
                Icon = TiArrowUpThick;
                break;
            case "Desc":
                Icon = TiArrowDownThick;
                break;
            default:
                Icon = TiArrowUnsorted;
                break;
        }

        return (
            <Icon
                onClick={() => setSortFields({...sortFields, [sortKey]: sortBy(sortFields[sortKey])})}
                             style={{fontSize: 'var(--bakery-fs6)'}}
            />
        )
    }
    return (
        <thead>
        <tr className={styles.header}>
            <th>Datum rez.<SortComp sortKey="createdAt"/></th>
            <th>Č.rez. <SortComp sortKey="orderNumber"/></th>
            <th>Dat.dodání<SortComp sortKey="orderDate"/></th>
            <th>Č.vyrobku <SortComp sortKey="productCode"/></th>
            <th>Zákazník <SortComp sortKey="client"/></th>
            <th>Email <SortComp sortKey="email"/></th>
            <th>Stav<SortComp sortKey="bpState"/></th>
            <th>Detaily</th>
        </tr>
        </thead>
    )
        ;
};

export default TableHead;