export const max = (numberArr) => {
    let result = numberArr?.sort((a,b)=>b-a)
return result? result[0] : 0
}

export const renumber = (arr,key) => {
    let res = arr?.sort((a,b)=>a[key]-b[key])
    return  res?.map((r,index)=>({...r,[key]:index+1})) || []
}

export const sortBy = (prev) =>{
    switch (prev) {
        case "Asc": return "Desc"
        case "Desc": return undefined
        default: return "Asc"
    }
}

