import {myArray} from "../../utils/myArray";
import {tableRowsArray} from "./tableRows";
import {dmyToYmd} from "../../utils/dateTime";

export const sortRows = (reservedBaskets, sortFields, stateFilter) => {
    if (!stateFilter) stateFilter = "all"
    let arr = new myArray(...tableRowsArray(reservedBaskets)?.filter(f => f.bpStateDB === stateFilter || stateFilter === "all"))

    sortFields?.createdAt === "Asc" && arr.sort((a, b) => dmyToYmd(a.createdAt) - dmyToYmd(b.createdAt))
    sortFields?.createdAt === "Desc" && arr.sort((a, b) => dmyToYmd(b.createdAt) - dmyToYmd(a.createdAt))

    sortFields?.orderNumber === "Asc" && arr.sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber))
    sortFields?.orderNumber === "Desc" && arr.sort((a, b) => Number(b.orderNumber) - Number(a.orderNumber))

    sortFields?.orderDate === "Asc" && arr.sort((a, b) => dmyToYmd(a.orderDate) - dmyToYmd(b.orderDate))
    sortFields?.orderDate === "Desc" && arr.sort((a, b) => dmyToYmd(b.orderDate) - dmyToYmd(a.orderDate))

    sortFields?.productCode && arr.sortObjABC123("productCode")

    sortFields?.client === "Asc" && arr.sort((a, b) => a.client4sort.localeCompare(b.client4sort))
    sortFields?.client === "Desc" && arr.sort((a, b) => b.client4sort.localeCompare(a.client4sort))

    sortFields?.email === "Asc" && arr.sort((a, b) => a.email.localeCompare(b.email))
    sortFields?.email === "Desc" && arr.sort((a, b) => b.email.localeCompare(a.email))

    sortFields?.bpState === "Asc" && arr.sort((a, b) => a.bpState.localeCompare(b.bpState))
    sortFields?.bpState === "Desc" && arr.sort((a, b) => b.bpState.localeCompare(a.bpState))

    return arr
}