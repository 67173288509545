import React, {useContext, useState} from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";


const OrderStateTabMenu = observer(() => {
    const {basketStore} = useContext(Context)

    const [key, setKey] = useState(0);
    const clickHandler = (k) => {
        setKey(k)
        basketStore.setStateFilter(Object.keys(basketStore.stateNamesAndCount)?.at(k))
    }
    return (
        <Tabs activeKey={key}
              onSelect={clickHandler}
              className="myTabsClass"
        >
            {Object.values(basketStore.stateNamesAndCount).map((x, i) =>
                <Tab key={i} eventKey={i} title={`${x.name} (${x.count})`}>
                </Tab>
            )}

        </Tabs>
    );
});

export default OrderStateTabMenu;