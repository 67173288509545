import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userAPI";
import {Spinner} from "react-bootstrap";
import Header from "./components/headerComponents/Header";
import LeftMenu from "./components/leftMenuComponets/LeftMenu";
import styles from "./pages/ProductPage.module.css";
import ProductPage from "./pages/ProductPage";

const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        check()
            .then(data => {
                user.setUser(data)
                user.setIsAuth(true)
                user.setIsAdmin(true) //TODO
            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <Spinner animation={"grow"}/>
    }

    return (
        <BrowserRouter >
            <Header/>
            <div className={styles.wrapper}>
                <LeftMenu/>
                <ProductPage/>
            </div>
        </BrowserRouter>
    );
});

export default App;
