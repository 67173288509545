import {fetchDoughs} from "../http/doughAPI";
import {fetchTypes} from "../http/typeAPI";
import {fetchAdditives} from "../http/addtiveAPI";
import {useEffect} from "react";

export const useLoadSystemData = ({setLoading, productStore, setDoughs, setTypes, setAdditives}) => {
    useEffect(() => {
        fetchDoughs()
            .then(data => {
                setDoughs(data)
                productStore.setDoughs(data)
            })
            .then(() => fetchTypes())
            .then(data => {
                setTypes(data)
                productStore.setTypes(data)
            })
            .then(() => fetchAdditives())
            .then(data => {
                setAdditives(data)
                productStore.setAdditives(data)
            })
            .catch(e => alert("fetchAllergens says" + e))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}