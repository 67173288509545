import {$authHost, $host} from "./index";

export const createDough = async (dough) => {
    try {
        const {data} = await $authHost.post('api/dough', dough)
        return data
    }
    catch (err) {
        console.log(err)
        alert("Не выполнено.\n" + err.message)
    }
}

export const fetchDoughs = async () => {
    const {data} = await $authHost.get('api/dough')
    return data
}

export const fetchOneDough = async (id) => {
    if(!id) return null
    const {data} = await $host.get('api/dough/'+id)
    return data
}
export const updateDough = async (doughs) => {
    const {data} = await $authHost.post('api/dough/update/',doughs)
    return data
}

export const deleteDough = async (id) => {
    if (id) {
        const {data} = await $authHost.post('api/dough/delete/' + id)
        return data
    } else return null
}
