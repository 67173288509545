import React, {useContext} from 'react';
import { Form} from "react-bootstrap";
import AdditiveRow from "./AdditiveRow";
import {Context} from "../../index";

const VariantItem = ({product, variant, setVariant, varIndex}) => {

    //console.log("VariantItem variant: "+JSON.stringify(variant,null,2))
    const {productStore} = useContext(Context)

    return (
        <div>
            <div className="">
                {variant.variant_types.map((variant_type, ind) =>
                    <div key={variant_type.id} className="pt-4 ps-4 pb-4"
                         style={{backgroundColor: (varIndex + ind) % 2 === 0 ? 'var(--white)' : 'var(--primary-10)'}}>
                        <div className="d-flex">
                            <div className="myCheckBox">
                                <Form.Check type="checkbox" checked={true}
                                            onChange={() => {
                                            }}
                                />
                            </div>
                            <div className="d-inline">
                                <div className="w-auto">{product.name}</div>
                                <div
                                    className="w-auto">{productStore.types.find(type => type.id === variant_type.type_id)?.name}, {variant.weight} g
                                </div>
                            </div>
                        </div>

                        <AdditiveRow key={variant_type.id}
                                     variant={variant}
                                     setVariant={setVariant}
                                     variant_type={variant_type}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VariantItem;