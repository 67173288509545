import React from 'react';
import cl from "./myInputNumber.module.css"


const MyInputNumber = React.forwardRef(({value, onChange, onBlur, className, style}, ref) => {
    return (
        <input ref={ref}
               className={cl.rectangle + ' ' + className}
               style={style}
               value={value || ""}
               onChange={e => onChange ? onChange(parseInt(e.target.value) || 0) : {}}
               onBlur={e => onBlur ? onBlur(parseInt(e.target.value) || 0) : {}}
        />
    );
})
export default MyInputNumber;
