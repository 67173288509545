import React, {useContext} from 'react';
import {Form} from "react-bootstrap";
import SelectTypes from "../commonComponents/SelectTypes";
import NinaInput from "../commonComponents/NinaInput";
import {Context} from "../../index";
import 'react-calendar/dist/Calendar.css';
import InputImg from "../imageCropperComponents/InputImg";
import TextInputX from "./TextInputX";
import SelectTypes2 from "../commonComponents/SelectType2";
import styles from "./ProductScreen.module.css"
import InputDropdown from "../commonComponents/InputDropdown";
import MyInputText from "../UI/MyInputText";

const ProductScreen = ({product, setProduct}) => {
    const {productStore} = useContext(Context);

    //console.log('ProductScreen product:'+JSON.stringify(product,null,2))
    const setProductKey = (key, value) => {
        setProduct({...product, [key]: value})
    }

    const nameInputHandler = (val) => {
        val?.length < 37 &&
        setProduct({...product, name: val})
    }

    const setProductKey5 = (code) => {
        const dough_id = productStore.doughs.find(d => d.code === code)?.id
        setProduct({...product, dough_id, code})
    }

    return (
        <Form className={styles.form}>
            <div className={styles.group}>
                <MyInputText
                    lable={"Název vyrobku (max. 36 znaků):"}
                    value={product?.name}
                    onChange={v => nameInputHandler(v.slice(0, 36))}
                    className={styles.productName}
                    placeholder="Povinná pole"
                />
            </div>

            <div className={styles.group}>
                <TextInputX
                    label={"Popis a složení vyrobku:"}
                    text={product?.composition}
                    setText={(v) => setProductKey("composition", v)}
                />
            </div>

            <div className={styles.group}>
                <InputDropdown
                    label={"Skupina kodů"}
                    titleStyle={styles.code}
                    state={product?.code}
                    setState={setProductKey5}
                    bpStateObj={
                        productStore.doughs.reduce((a, d) => ({...a, [d.code]: {name: d.code}}), {})
                    }
                />
            </div>

            <div className={styles.group}>
                <InputImg
                    label={"Obrázek vyrobku:"}
                    product={product}
                    setProduct={setProduct}/>
            </div>

            <div className={styles.group}>
                <SelectTypes2
                    label={"Tvar"}
                    types={productStore.types}
                    selectedTypes={product?.types}
                    variants={product?.variants}
                    product={product}
                    setProduct={setProduct}
                    classNumber={styles.number}
                />
            </div>

            <div className={styles.group}>
                <TextInputX
                    label={"Doplňující informace:"}
                    text={product?.description}
                    setText={(v) => setProductKey("description", v)}
                />
            </div>

            <div className={styles.group}>
                <NinaInput
                    label={"Minimální trvanlivost, dny"}
                    className={styles.nina}
                    val={product?.shelf_life}
                    setValue={v => {
                        v < 100 && setProductKey("shelf_life", v)
                    }}
                    inputType={"number"}/>
            </div>

            <div className={styles.group}>
                <SelectTypes
                    label={"Alergeny"}
                    types={productStore.allergens}
                    selectedTypes={product?.allergens}
                    setSelectedTypes={(arr => setProductKey("allergens", arr))}
                    foreign_key={"allergen_id"}
                    width={321}
                    height={40}
                />
            </div>
            <div>
                <TextInputX
                    label={"Komentář"}
                    text={product?.comment || ""}
                    setText={(v) => setProductKey("comment", v)}
                    style={{width: 795, minHeight: 40}}
                />
            </div>
        </Form>
    );
};

export default ProductScreen;
