import React, {useContext, useState} from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductScreen from "./ProductScreen";
import ProductScreen2 from "./ProductScreen2";
import VariantItem from "../variantComponents/VariantItem";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const Product = observer(({product, setProduct}) => {
    const [tabKey, setTabKey] = useState('home');
    const {basketStore} = useContext(Context)

    const counter = i => i < 0 ? 0 : product.variants.slice(0, i).reduce((sum, v) => sum + v.variant_types.length, 0)
    return (
        <Tabs
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="myTabsClass"
        >
            <Tab eventKey="home" title="Karta vyrobku"
                 style={{marginTop:0,height:basketStore.basketXY.h-300,overflowY:'scroll'}}
            >
                <ProductScreen product={product}
                               setProduct={setProduct}
                />
            </Tab>
            <Tab eventKey="profile" title="Zobrazení na webu"
                 style={{marginTop:0,height:basketStore.basketXY.h-300,overflowY:'scroll'}}
            >
                <ProductScreen2 product={product}
                                setProduct={setProduct}
                />

            </Tab>
            <Tab eventKey="contact" title="Rezervace vyrobku"
                 style={{marginTop:0,height:basketStore.basketXY.h-300,overflowY:'scroll'}}
            >
                {product.variants.map((variant, ind) =>
                    <div key={variant.id}>
                        <VariantItem
                            varIndex={counter(ind)}
                            product={product}
                            variant={variant}
                            setVariant={v => setProduct({
                                ...product, variants: product.variants.map(e => e.id === v.id ?
                                    v : e
                                )
                            })}
                        />
                    </div>
                )
                }
            </Tab>
        </Tabs>
    );
});

export default Product;