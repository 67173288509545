import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import {Spinner, Table} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import OrderStateTabMenu from "./OrderStateTabMenu";
import {useLoadBaskets} from "../../hooks/useLoadBaskets";

const ReservedProducts = observer(({sortFields,setSortFields}) => {

    const {basketStore} = useContext(Context)
    const [loading,setLoading] = useState(true)


    useLoadBaskets(basketStore,setLoading)
    if(loading) return <Spinner animation={"grow"}/>

    //console.log('ReservedProducts basketStore.baskets:'+ JSON.stringify(basketStore.baskets));

    return (
        <div style={{backgroundColor: 'var(--white'}}>
            <OrderStateTabMenu />
            <div
                style={{marginTop: 0, height: basketStore.basketXY.h - 320, overflowY: 'scroll', width: '100%'}}
            >
            <Table responsive>
                <TableHead sortFields={sortFields} setSortFields={setSortFields}/>
                <TableBody sortFields={sortFields}/>
            </Table>
            </div>
        </div>
    );
});

export default ReservedProducts;


