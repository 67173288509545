import React, {createContext} from 'react';
import App from './App';
import UserStore from "./store/UserStore";
import './index.css'
import ProductStore from "./store/ProductStore";
import BasketStore from "./store/BasketStore";
import ProductionStore from "./store/ProductionStore";
import {createRoot} from "react-dom/client";
import InfoStore from "./store/InfoStore";


export const Context = createContext(null)
console.log(process.env.REACT_APP_API_URL)
export const infoStore = new InfoStore()

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        productStore: new ProductStore(),
        basketStore: new BasketStore(),
        productionStore: new ProductionStore()
    }}>
        <App/>
    </Context.Provider>
);

