import React, {useEffect, useState} from 'react';
import styles from "../../pages/ProductPage.module.css";
import {useNavigate, useLocation} from "react-router-dom";
import {authRoutes} from "../../routes";

const leftMenuItems = authRoutes
    .filter(r=> r.leftMenu)
    .map(route => ({name: route.leftMenu , routeName: route.path}))

const LeftMenu = () => {

    const navigate = useNavigate()

    const [leftMenuItem, setLeftMenuItem] = useState(leftMenuItems[0]);
    const leftMenuClickHandler = (item) => {
        setLeftMenuItem(item)
        navigate(item.routeName)
    }

    const location = useLocation();
    useEffect(() => {
        const item = leftMenuItems.find(item => item.routeName.toLowerCase() === location.pathname)
        if (item) {
            setLeftMenuItem(item)
        }
    }, [location.pathname])

    return (
        <div className={styles.leftMenu}>
            {
                leftMenuItems.map(item =>
                    <div key={item.name} className={item.name === leftMenuItem.name ? "left-menu-act" : "left-menu"}>
                        <span onClick={() => leftMenuClickHandler(item)}>{item.name}</span>
                    </div>
                )}
        </div>
    );
};

export default LeftMenu;