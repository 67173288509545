import React, {useContext} from 'react';
import {Context, infoStore} from "../../index";
import SelectAdditives from "./SelectAdditives";
import styles from "./AdditiveRow.module.css";
import {daysOfWeekCZ} from "../../utils/daysOfWeek";
import SelectTypes from "../commonComponents/SelectTypes";
import st from "./SelectAdditives.module.css"
import {Col, Form} from "react-bootstrap";
import MyInputNumber from "../UI/MyInputNumber";
import Row from "react-bootstrap/Row";

const AdditiveRow = ({variant, setVariant, variant_type}) => {
    const {productStore} = useContext(Context)
    const setTime = (key, time) => {
        setVariant({
            ...variant,
            variant_types: variant.variant_types.map(v =>
                v.type_id === variant_type.type_id ? {...v, [key]: time} : v)
        })
    }

    const variantAdditiveHandler = (additives) => {
        setVariant({
            ...variant,
            variant_types: variant.variant_types.map(e => e.id === variant_type.id ?
                {...e, additives} : e)
        })
        //console.log("variantAdditiveHandler additives:"+JSON.stringify(additives,null,2))
    }
    if (!variant_type || variant_type.length < 1) {
        alert("Need at least one type!")
        return (<></>)
    }

    //.replace(/ /g,'')
    const aaa = infoStore.intervals?.length>0 ? infoStore.intervals.at(-1) : ""
    const [defaultFrom, defaultTo] = aaa.name.replace(/ /g,'').split("-") || ["", ""]
    return (
        <div className="mt-5">
            <Row>
                <Col md={3}>
                    <Form.Group>
                        <div style={{fontSize: 12}}>Základní cena vyrobku:</div>
                        <div>
                            <MyInputNumber className={styles.inputField}
                                           value={variant.price}
                                           onChange={v => setVariant({...variant, price: v})}/>
                        </div>
                    </Form.Group>
                </Col>

                <Col md={9}>
                    <div className="" style={{fontSize: 12}}>Časové rozmezí pro rezervace s vyzvednutím ještě dnes:
                    </div>

                    <div className="d-flex  w-50">
                        <div className="mb-2 me-4 d-flex">
                            <div className={styles.fromTo}>Od</div>
                            <input className={styles.inputField}
                                   id="time_from"
                                   type="time"
                                   value={variant_type.time_from || defaultFrom}
                                   onChange={(t) => {
                                       setTime("time_from", t.target.value)
                                   }}/>
                        </div>
                        <div className="mb-2 w-50 d-flex">
                            <div className={styles.fromTo}>Do</div>
                            <input className={styles.inputField}
                                   id="time_to"
                                   type="time"
                                   value={variant_type.time_to || defaultTo}
                                   onChange={(t) => {
                                       setTime("time_to", t.target.value)
                                   }}/>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className={styles.selectContainer}>

                <div className={styles.daysRow}>
                    <span style={{width: 281, marginBottom: 5, fontSize: 12}}>Množství vyrobku k rezervace, ks</span>
                    <div className="d-flex">
                        {daysOfWeekCZ.map(d =>
                            <span key={d.name} className={st.daysOfWeek}>{d.name}</span>
                        )}
                    </div>
                </div>

                <SelectTypes types={productStore.additives}
                             selectedTypes={variant_type.additives}
                             setSelectedTypes={arr => variantAdditiveHandler(arr)}
                             foreign_key={"additive_id"}
                             styleName={styles.selectTypes}
                             SelectTypeAddition={SelectAdditives}
                />


            </div>

        </div>
    );
};

export default AdditiveRow;