import {$authHost} from "./index";

export const fetchFirm = async () => {
    const {data} = await $authHost.get('api/firm')
    return data
}
export const updateFirm = async (values) => {
    const {data} = await $authHost.post('api/firm/update',values)
    return data
}
