import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import styles from "./InputImg.module.css";
import x_close_btn from "../../img/icon-delete.png"
import {calculateSize} from "./calculateSize";
import ImageCropper from "./ImageCropper";
import {previewImage} from "./previewImage";


const cropDimensions = {cropWidth: 250, cropHeight: 200} // !!!!!!!!!!!!!

let imgWidth = 0;
let imgHeight = 0;
const setImgDim = (cropDimensions, incomingDimensions) => {

    const {cropWidth, cropHeight} = cropDimensions

    if (incomingDimensions.width && incomingDimensions.height) {
        if (incomingDimensions.width > incomingDimensions.height) {   // Horizontal:
            imgHeight = cropHeight;
            imgWidth = incomingDimensions.width * imgHeight / incomingDimensions.height
        } else {                                                    // Vertical:
            imgWidth = cropWidth;
            imgHeight = incomingDimensions.height * imgWidth / incomingDimensions.width
        }
    }
    return {imgWidth, imgHeight}
}

const beginningCrop = (imageDimensions, cropDimensions) => {

    const {cropWidth, cropHeight} = cropDimensions
    const {imgWidth, imgHeight} = imageDimensions
    let cropX = 0
    let cropY = 0
    if (imgWidth && imgHeight) {
        if (imgWidth > imgHeight) {   // Horizontal:
            cropX = (imgWidth - cropWidth) / 2.
        } else {                                                    // Vertical:
            cropY = (imgHeight - cropHeight) / 2.
        }
    }
    return  {unit: "px", width: cropWidth, height: cropHeight, x: cropX, y: cropY}
}


const InputImg = ({product, setProduct, label}) => {
    const [showCrop, setShowCrop] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [imgDimension, setImgDimension] = useState({})
    const [crop, setCrop] = useState({unit: "px", width: 0, height: 0, x: 0, y: 0})
    const [isImageLoading, setIsImageLoading] = useState(false)
    const input = document.getElementById('image_uploads')

    function onFileSelected(e) {
        setProduct({...product, file: e.target.files[0]})
        setShowCrop(true)
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', (el) => {
                setImgSrc(el.target.result || '')
                calculateSize(el.target.result).then(incoming => {
                    const iDim = setImgDim(cropDimensions, incoming)
                    setImgDimension(iDim)
                    setCrop(beginningCrop(iDim, cropDimensions))
                })
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const resetImg = () => {
        setProduct({...product, img: null, file: null})
        setImgSrc(null)
        if (input) input.value = null
    }

    const onFileCropped = ({blob}) => {
        setShowCrop(false)
        setImgSrc('')
        setIsImageLoading(true)
        previewImage(blob)
            .then(res => {
                setProduct({...product, img: res})
            })
            .catch(e => console.log(e))
            .finally(() => setIsImageLoading(false))
    }
    return (
        <div>
            {label &&
                <div className={styles.label}>{label}</div>
            }
            {Boolean(product.img) || isImageLoading ||
                <label className="button-text-only" htmlFor="image_uploads">Přidat položku</label>
            }
            <input
                id="image_uploads"
                style={{opacity: 0, width: '1px'}}
                type="file"
                accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf,image/bmp"
                onChange={onFileSelected}
            />
            <>
                {isImageLoading ?
                    <Spinner animation="border" variant="primary" className={styles.spinner}/>
                    :
                    <>
                        {Boolean(product.img) &&
                            <div className="d-flex">
                                <img className={styles.image}
                                     alt={product.name}
                                     src={process.env.REACT_APP_IMG_URL + product.img}
                                     style={{width: cropDimensions.cropWidth, height: cropDimensions.cropHeight}}
                                />
                                <img src={x_close_btn} className={styles.x_close}
                                     alt="x"
                                     onClick={resetImg}
                                />
                            </div>
                        }
                    </>
                }
            </>
            {showCrop && !Boolean(product.img) &&
                <ImageCropper imageDimensions={imgDimension}
                              crop={crop}
                              setCrop={setCrop}
                              imgSrc={imgSrc}
                              onFileCropped={onFileCropped}
                />
            }
        </div>
    )
};

export default InputImg;
