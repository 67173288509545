import React from 'react';
import styles from "./SelectAdditives.module.css";
import MyInputNumber from "../UI/MyInputNumber";
import {daysOfWeekDB} from "../../utils/daysOfWeek";

const SelectAdditives = ({change, selected}) => {

    const changeAmountPerDay = (id, key, value) => change(key, value, id);

    return (
        <div className="d-inline-flex ">
            {daysOfWeekDB.map(({name:day}) => (
                <div key={day} className="d-inline-flex ">
                    <MyInputNumber className={styles.dayQuantity} value={selected[day]}
                                   onChange={v => changeAmountPerDay(selected.id, day, parseInt(v) || 0)}/>
                    {selected[day] && selected[day] !== "" ?
                        <div className={styles.unit}>
                            ks
                        </div> :
                        <div className={styles.unit}></div>
                    }
                </div>
            ))}
        </div>
    );
};

export default SelectAdditives;