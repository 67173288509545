import {
    ADMIN_ROUTE, DASHBOARD_ROUTE, DIRECTORY_ROUTE, EDIT_PRODUCT_ROUTE, LIST_ROUTE,
    LOGIN_ROUTE, NEW_PRODUCT_ROUTE, ORDERS_ROUTE,
    QUALITY_ROUTE,
} from "./utils/consts";
import Directories from "./pages/Directories";
import Admin from "./pages/Admin";
import Auth from "./pages/Auth";
import OrdersPage from "./pages/OrdersPage";
import QualityPage from "./pages/QualityPage";
import EditProduct from "./pages/EditProduct";
import NewProduct from "./pages/NewProduct";
import List from "./pages/List";
import DashBoard from "./pages/DashBoard";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: LIST_ROUTE,
        Component: List,
        leftMenu: "Přehled"
    },
    {
        path: NEW_PRODUCT_ROUTE,
        Component: NewProduct,
        leftMenu: "Nový vyrobek"
    },
    {
        path: ORDERS_ROUTE,
        Component: OrdersPage,
        leftMenu: "Objednávky"
    },

    {
        path: QUALITY_ROUTE,
        Component: QualityPage,
        leftMenu: "Kvalita"
    },

    {
        path: DIRECTORY_ROUTE,
        Component: Directories,
        leftMenu: "Správa"
    },

    {
        path: EDIT_PRODUCT_ROUTE,
        Component: EditProduct
    },
    {
        path: DASHBOARD_ROUTE,
        Component:  DashBoard,
        //leftMenu: "Dashboard"
    },
]

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
]
