import React, {useContext, useEffect} from 'react';
import styles from './directory.module.css'
import InputTextLi from "./InputTextLi";
import {makeId} from "../../utils/dateTime";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const SystemSettings = observer(({doughs, setDoughs, types, setTypes, additives, setAdditives}) => {
    const {basketStore} = useContext(Context)


    useEffect(() => {
        !doughs?.length && setDoughs([{id: makeId(), name: 'Pšeničný', code: 'PS', isNew: true}])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.systemSettings} style={{height:basketStore.basketXY.h*0.7,overflowY:'scroll'}}>
            <div>
                <div className={styles.text1}>Kódování a nacenění výrobků</div>
            </div>
            {/*Dough*/}
            <InputTextLi liTexts={doughs} setLiTexts={setDoughs}
                         rectStyle={styles.liTextRectangle}
                         textStyle={styles.liText}
                         codeStyle={styles.liTextCode}
                         priceStyle={styles.liTextPrice}
                         code={"code"}
                         mainName={"Druhy těsta dle surovinového složení"}
                         codeName={"Kod druhu vyrobku"}
            />
            <InputTextLi liTexts={types} setLiTexts={setTypes}
                         rectStyle={styles.liTextRectangle}
                         textStyle={styles.liText}
                         codeStyle={styles.liTextCode}
                         priceStyle={styles.liTextPrice}
                         code={"code"}
                         mainName={"Druhy pekařských vyrobků"}
                         codeName={"Kod druhu vyrobku"}
            />

            <InputTextLi liTexts={additives} setLiTexts={setAdditives}
                         rectStyle={styles.liTextRectangle}
                         textStyle={styles.liText}
                         codeStyle={styles.liTextCode}
                         priceStyle={styles.liTextPrice}
                         code={"code"}
                         price={"price"}
                         mainName={"Variánty na webu"}
                         codeName={"Kod přídavku"}
                         priceName={"Cena přídavku"}
            />

        </div>
    );
});

export default SystemSettings;