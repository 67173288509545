import React from 'react';
import {observer} from "mobx-react-lite";
import Product from "../components/productComponets/Product";
import ContentTopMenu from "../components/productListComponents/ContentTopMenu";
const EditProduct = observer(({product, setProduct,...props}) => {

    return (
        <div className="pt-2 bg-white">
            <ContentTopMenu {...props}/>
            <Product product={product}
                     setProduct={setProduct}
            />
        </div>
    );
});

export default EditProduct;
