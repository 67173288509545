export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const LIST_ROUTE = '/'
export const NEW_PRODUCT_ROUTE = '/new_product'
export const EDIT_PRODUCT_ROUTE = '/edit'
export const ORDERS_ROUTE = '/orders'
export const QUALITY_ROUTE = '/quality'
export const DIRECTORY_ROUTE = '/directories'
export const DASHBOARD_ROUTE = '/dashboard'



