export const calculateSize = function (url) {
    return new Promise(function (resolve, reject) {
        const image = document.createElement('img');
        image.addEventListener('load', function (e) {
            resolve({
                width: e.target.width,
                height: e.target.height,
            });
        });

        image.addEventListener('error', function () {
            reject();
        });

        image.src = url;
    });
};