import {$authHost, $host} from "./index";

export const fetchProductionAll = async () => {
    const {data} = await $host.get('api/production')
    return data
}
export const planProductionProduct = async (product_id) => {
    const {data} = await $authHost.post('api/production/planProductionProduct/' + product_id)
    return data
}


/*
export const planProductionAll = async () => {
    const {data} = await $authHost.post('api/production/planProduction')
    return data
}
export const planProductionBranch = async (variant) => {
    const {data} = await $authHost.post('api/production/planProductionBranch/', variant)
    return data
}
export const changeProductionStatus = async (values) => {
    const {data} = await $authHost.post('api/production/changeStatus/',values)
    return data
}
*/
