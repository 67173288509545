import icon_status from "./img/icon-status.svg"
import icon_status2 from "./img/icon-status-2.svg"
import icon_status3 from "./img/icon-status-3.svg"
import icon_status4 from "./img/icon-status-4.svg"

const statesCz = {
    InBasket: {name: "V košíku", icon: icon_status, borderColor: "var(--danger)"},
    Reserved: {name: "Nevyřízená", icon: icon_status, borderColor: "var(--danger)"},
    Issued: {name: "Vyřízená", icon: icon_status2, borderColor: "#31c618"},
    Canceled: {name: "Stornováná", icon: icon_status3, borderColor: "#f2a663"},
    Processing: {name: "Vyřízuje se", icon: icon_status4, borderColor: "var(--secondary)"},
}
const all = {all: {name: "Všechny rezervace"}}


const deleteKey = (obj, key) => {
    let x = Object.assign({}, obj)
    delete x[key]
    return x
}
export const bpStateObj = deleteKey(statesCz, "InBasket")

export const countByCategories = (baskets) => {
    const sumBy = (key) =>
        baskets.reduce((sum, basket) => sum + basket.basket_products?.filter(bp => bp.state === key)?.length || 0, 0)

    let cc0 = {all: {...all.all, count: baskets.reduce((sum, basket) => sum + basket.basket_products.length, 0)}}

    let cc = Object.entries(statesCz).reduce((accum, [category, obj]) =>
        Object.assign(accum, {[category]: {...obj, count: sumBy(category)}}), cc0)
    delete cc.InBasket
    //console.log('cc >>>' + JSON.stringify(cc, null, 2))
    return cc
}