import React, {useContext} from 'react';
import MyModal from "../../myModalComponents/MyModal";
import styles from "./output.module.css"
import commonStyles from '../../UI/common.module.css'
import icon_delete from "../img/icon-delete.svg"
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import OutputToWeb from "./OutputToWeb";
import {Context} from "../../../index";
import {FormattedOrders} from "./FormattedOrders";
import {openHtmlInNewTab} from "./openHtmlInNewTab";


const OutputSettings = ({show, handleClose, sortFields, setSortFields}) => {
    const {basketStore} = useContext(Context)
    const reservedBaskets = basketStore.rBaskets
    const
        height = 362,
        heightBody = height,
        width = 632,
        top =160,
        left = (window.innerWidth -width) / 2;

    const modalXY = {
        height, heightBody, width, top, left, containerHeight: 200
    }

    const fo = new FormattedOrders(sortFields, setSortFields);
    const formatCategories = fo.formattingCategories
    const x = fo.formattedOrders

    const sortSettings = Object.keys(x).filter(r => x[r].column > 0)
    const fNext = (i) => sortSettings?.at(i + 1) || {}

    const OutputFormat = ({category}) => {
        return (
            <div className="d-flex me-2">
                {x[category] ?
                    <div className={x[category].isSelected ? commonStyles.circleSelected : commonStyles.circle}
                                    onClick={() => x[category].setI(x[category])}
                /> : <></>}{x[category]?.name || ""}
            </div>
        )
    }

    return (
        <MyModal modalXY={modalXY}
                 isOpenModal={show}
                 closeModal={handleClose}
                 isMouseLeaveCloseModal={false}
        >
            <div className={styles.cont}>
                <div className="d-flex justify-content-between ">
                    <div className={styles.r1}>
                        {x.title}
                    </div>
                    <img src={icon_delete} onClick={handleClose} alt="" style={{cursor: 'pointer'}}/>
                </div>

                <Row className="mt-4 ">
                    <Col md={6} className="d-flex">
                        <div className={x.all.isSelected ? commonStyles.circleSelected : commonStyles.circle}
                             onClick={() => x.all.setIS("all")}
                        />
                        {x.all.name}</Col>
                </Row>

                {sortSettings.map((f, i) =>
                    <div key={i}>
                        {i % 2 === 0 ?
                            <Row className="mt-4">
                                <Col md={6} className="d-flex">
                                    <div className={x[f].isSelected ? commonStyles.circleSelected : commonStyles.circle}
                                         onClick={() => x[f].setI(f)}
                                    />
                                    {x[f].name}
                                </Col>
                                <Col md={6} className="d-flex">
                                    <div className={x[fNext(i)].isSelected ? commonStyles.circleSelected : commonStyles.circle}
                                         onClick={() => x[fNext(i)].setI(fNext(i))}
                                    />
                                    {x[fNext(i)].name}
                                </Col>
                            </Row> :
                            <></>
                        }
                    </div>
                )}

                <div className="d-flex mt-4">
                    {formatCategories.map(format =>
                        <OutputFormat key={format} category={format}/>
                    )}
                </div>

                <div className="d-flex ms-2">
                    <div className={styles.btn1}
                         role="link"
                         onClick={() => openHtmlInNewTab({OutputToWeb, sortFields, reservedBaskets})}
                    >Náhled
                    </div>
                    <div className={styles.btn2}>Tisk</div>
                    <div className={styles.btn3}>Export</div>
                </div>

            </div>
        </MyModal>
    );
}

export default OutputSettings;