import React, {useState} from 'react';
import styles from "./TextInputX.module.css";
import x_close_btn from "../../img/icon-delete.png";
import ResizableTextarea from "../commonComponents/ResizableTextarea";

const TextInputX = ({text, setText,label,style}) => {
    const [showField, setShowField] = useState(text?.length);

    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}
            {showField || text?.length ?
                <div className={`${styles.container}`}>
                    <ResizableTextarea
                        text={text}
                        setText={setText}
                        externStyles={style}/>

                    <img src={x_close_btn} alt="x" className={styles.x_close}
                         onClick={() => {
                             setText("");
                             setShowField(false)
                         }}/>
                </div>
                :
                <div className="button-text-only" onClick={() => setShowField(true)}>Přidat položku</div>
            }

        </div>
    );
};

export default TextInputX;