import React from 'react';
import {Container} from "react-bootstrap";

const Admin = () => {


    return (
        <Container fluid className="ml-5">

        </Container>
    );
};

export default Admin;
