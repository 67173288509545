import React, {useContext, useEffect, useState} from 'react';
import {Spinner} from "react-bootstrap";
import {Context, infoStore} from "../index";
import {createProduct, deleteProduct, fetchBigProducts, updateProduct, updateProductNumbers} from "../http/productAPI";
import {fetchTypes} from "../http/typeAPI";
import {fetchAllergens} from "../http/allergenAPI";
import {fetchAdditives} from "../http/addtiveAPI";
import styles from "./ProductPage.module.css"
import {renumber} from "../utils/sorti";
import {fetchProductionAll, planProductionProduct} from "../http/productionAPI";
import {makeId} from "../utils/dateTime";
import {useNavigate} from "react-router-dom";
import {EDIT_PRODUCT_ROUTE, LIST_ROUTE, LOGIN_ROUTE} from "../utils/consts";
import {fetchDoughs} from "../http/doughAPI";
import AppRouter from "../components/AppRouter";
import {fetchFirm} from "../http/firmAPI";
import {fetchIntervals} from "../http/intervalAPI";
import {observer} from "mobx-react-lite";

const ProductPage = observer(() => {
    const {productStore} = useContext(Context)
    const {basketStore} = useContext(Context)
    const {productionStore} = useContext(Context)
    const [products, setProducts] = useState([])
    const emptyProduct = {
        name: '',
        img: '',
        composition: '',
        description: '',
        shelf_life: 0,
        reserve: false,
        days: 0, variants: [], types: [], allergens: [],
    }
    const [product, setProduct] = useState(emptyProduct);

    const [needToReload, setNeedToReload] = useState(makeId())
    const [loading, setLoading] = useState(true);

    const {user} = useContext(Context)
    const navigate = useNavigate()

    useEffect(() => {
        if (!user?.isAuth) {
            navigate(LOGIN_ROUTE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isAuth])

    useEffect(() => {
        basketStore.setBasketXY({
            w: window.innerWidth,
            h: window.innerHeight
        })
        const onResize = () => {
            basketStore.setBasketXY({
                w: window.innerWidth,
                h: window.innerHeight
            })
        }
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        fetchDoughs().then(data => productStore.setDoughs(data))
        fetchTypes().then(data => productStore.setTypes(data))
        fetchAdditives().then(data => productStore.setAdditives(data)).catch(e => alert(`fetchAdditives says  ${e}`))
        fetchAllergens().then(data => productStore.setAllergens(data))
        fetchIntervals().then(data => infoStore.setIntervals(data))
        fetchFirm().then(data => infoStore.setFirm(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        fetchProductionAll()
            .then(productions => productionStore.setProductions(productions))
            .then(() => fetchBigProducts()
                .then(products => {
                    productStore.setProducts(products)
                    setProducts(productStore.products)
                    //wait(2000)
                }))
            .catch(e => alert(`ups...  ${e}`))
            .finally(() => setLoading(false)
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToReload])

    if (loading) {
        return <Spinner className="ms-5 mt-5" animation="border" variant="primary"/>
    }

    const editProductHandler = (product) => { // clicked in ListButton.js
        productStore.setCurrentProduct(product)
        setProduct(product)
        navigate(EDIT_PRODUCT_ROUTE)
        //setComponent({fn: EditProduct})
    }

    const delProduct = (id) => {
        setProducts(products.filter(p => p.id !== id))
    }

    const changeNumberHandler = (value, id) => {
        setProducts(products.map(p =>
            p.id === id ?
                {...p, number: value, needRenumber: true, isItChanged: true, warning: false}
                :
                p.number === value ? // if number is not unique then warning
                    {...p, warning: true}
                    :
                    {...p, warning: false}
        ))
    }

    const blurNumberHandler = (value, id) => {
        setProducts(products.map(p =>
            p.id === id && products.find(f => f.number === p.number && f.id !== id) ?
                {...p, warning: true}
                :
                p.id !== id && p.number === value ?
                    {...p, warning: true}
                    :
                    {...p, warning: false}
        ))
    }

    /**
     * Save button handler calls from EditProduct and from NewProduct.
     * If product.innerStatus === "NewProduct" then create new product
     * else update product
     */
    const saveButtonHandler = () => {
        let curProduct = {
            ...product,
            variants: product.variants.filter(v => v.weight && v.weight !== ''), // remove empty variants
        }

        if (curProduct?.innerStatus === "NewProduct") {
            const prod = [...products, {...curProduct, id: makeId()}];
            setProducts(prod);
            productStore.setProducts(prod);
            setProduct(emptyProduct)
            navigate(LIST_ROUTE)
        } else {
            const xs = products.map(p => p.id === curProduct.id ? curProduct : p)
            setProducts(xs);
            productStore.setProducts(xs);
            setProduct(curProduct)
        }
    }

    /**
     * Save button handler calls from List
     */
    const saveButtonHandler4List = () => {
        const prods4Delete = productStore.products.filter(p => !products.some(f => f.id === p.id));
        productStore.setDeletedProducts([...productStore.deletedProducts, ...prods4Delete])
        if (prods4Delete.length > 0) productStore.setChangesA(true)

        const xs = renumber(products, "number")?.map(p => ({...p, warning: false}))
        setProducts(xs)
        productStore.setProducts(xs)
        setProduct(product)
    }

    const updateNumbers = async () => {
        if (products.find(p => p.needRenumber) === undefined) return

        try {
            const prods4Update = products.map(n => ({id: n.id, number: n.number}))
            if (prods4Update.length) {
                const formData = new FormData()
                formData.append('products', JSON.stringify(prods4Update))
                await updateProductNumbers(formData)
            }

        } catch (e) {
            alert(e)
        }
    }

    const deleteProducts = async () => {
        try {
            for (const p of productStore.deletedProducts) {
                await deleteProduct(p.id)
            }
        } catch (e) {
            alert(e.message)
        }
        productStore.setDeletedProducts([])
        productStore.setChangesA(false)
    }

    const cancelHandler = () => {
        setProducts(productStore.products)
        setProduct(productStore.currentProduct)
        productStore.setChangesA(false)
        navigate(LIST_ROUTE)
    }

    const writingChangesToDB = () => {
        (async () => {
            /* 1. Delete products*/
            await deleteProducts()

            /* 2. Update numbers*/
            await updateNumbers()

            /* 3. Create new products */
            for (const p of productStore.products.filter(e => e.innerStatus === "NewProduct")) {
                try {
                    const formData = formProductData({...p, id: undefined}, null)
                    const productId = await createProduct(formData)

                    productStore.setProducts(productStore.products.map(prod =>
                        prod.id === p.id ? {
                            ...prod, id: productId, innerStatus: undefined,
                            //isItChanged:undefined
                        } : prod))
                    setProducts(products.map(prod =>
                        prod.id === p.id ? {
                            ...prod, id: productId, innerStatus: undefined,
                            // isItChanged:undefined
                        } : prod))
                } catch (e) {
                    alert("writingChangesToDB.new error: " + e)
                }
            }


            /* 4. Update products */
            for (const p of productStore.products.filter(e => e.isItChanged)) {
                await updateProduct(formProductData(p, null))
                await planProductionProduct(p.id)
            }

            productStore.setProducts(
                productStore.products.map(p =>
                    productionStore.productions.some(f => f.product_id === p.id) ? p : {...p, reserve: false}
                )
            )
        })()
            .then(() => {
                productStore.setProducts(productStore.products.map(p => ({
                    ...p,
                    needRenumber: false,
                    isItChanged: undefined
                })))
                setProduct({...product, needRenumber: false, isItChanged: undefined})
                setNeedToReload(Date.now())
                productStore.setChangesA(false)
                //navigate(LIST_ROUTE)
            })
            .catch(e => alert(`writingChangesToDB.update Error:   ${e}`))

        //setLeftMenuItem({fn: List})
    }

    const setProductHandler = (p) => {
        setProduct({...p, isItChanged: true})
    }

    const setVisibility = (productId) => {
        const setProductVisibility = () => {
        }; //tmp

        const status = products.find(p => p.id === productId)?.status === "ShowInTheShop" ? "DontShow" : "ShowInTheShop"
        setProductVisibility(status)
        setProduct({...product, status, isItChanged: true})
        setProducts(products.map(p => p.id === productId ? {...p, status, isItChanged: true} : p))
    }

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.contentArea}>
                <div className={styles.bodyArea}>
                    <AppRouter
                        products={products}
                        delProduct={delProduct}
                        blurNumberHandler={blurNumberHandler}
                        changeNumberHandler={changeNumberHandler}
                        editProductHandler={editProductHandler}
                        product={product}
                        setProduct={setProductHandler}
                        setVisibility={setVisibility}
                        yesBtnHandler={saveButtonHandler}
                        yesBtnHandlerList={saveButtonHandler4List}
                        cancelHandler={cancelHandler}
                        publishBtnHandler={writingChangesToDB}
                    />
                </div>
            </div>
        </div>
    );
});

export default ProductPage;

const formProductData = (product, file) => {
    const formData = new FormData()
    product.id && formData.append('id', product.id || 0)
    formData.append('name', product.name.slice(0, 36))
    formData.append('comment', product.comment?.slice(0, 240) || '')
    formData.append('dough_id', product.dough_id || '')
    formData.append('status', product.status || "")
    formData.append('reserve', product.reserve || false)
    formData.append('composition', product.composition?.slice(0, 240) || '')
    formData.append('description', product.description?.slice(0, 240) || '')
    formData.append('shelf_life', product.shelf_life || 0)
    formData.append('days', product.days || 0)
    formData.append('file', file)
    formData.append('file_name', (product.img))
    formData.append('variants', JSON.stringify(product.variants))
    formData.append('selectedTypes', JSON.stringify(product.types))
    formData.append('selectedAllergens', JSON.stringify(product.allergens))
    return formData
}