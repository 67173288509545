import {dateToDDMMYY} from "../../utils/dateTime";
import {bpStateObj} from "./basketProductStates";
import {codeFormer} from "../../utils/utils";

function tableRows(basket, bp) {

    return {
        createdAt: dateToDDMMYY(bp.createdAt),
        orderNumber: basket.id.toString(), //+ '0' + (bp.id % 1000).toString(),
        orderDate: dateToDDMMYY(bp.order_date),
        productCode: codeFormer(bp),
        userId: basket.user.id,
        client: basket.user.surname + " " + basket.user.name,
        client4sort: basket.user.surname,
        email: basket.user.email,
        phone: basket.user.phone,
        amount: basket.basket_products.reduce((sum, bp) => sum + bp.amount, 0),
        price: basket.basket_products.reduce((sum, bp) => sum + bp.amount * bp.price, 0),
        basketId: basket.id,
        bpId: bp.id,
        bpState: bpStateObj[bp.state]?.name || "",
        bpStateDB: bp.state,

        button1: "button1",
        button2: "button2",
        deliveryAddress: `Holandská 122 ${''} 266 01 Beroun`,
    }

}

export const tableRowsArray = (reservedBaskets) => {
    //console.log("reservationTable rBaskets>>>"+JSON.stringify(rBaskets,null,2))
    let arr = []
    try {
        reservedBaskets?.forEach(basket =>
            basket.basket_products?.forEach((bp, i) =>
                arr.push(tableRows(basket, bp, i))
            ))
        return arr
    } catch (e) {
        alert("error:" + e)
    }
}
