import {$authHost, $host} from "./index";

export const fetchTypes = async () => {
    const {data} = await $host.get('api/type')
    return data
}

export const updateType = async (types) => {
    const {data} = await $authHost.post('api/type/update/',types)
    return data
}
