import React from 'react';

import {sortRows} from "../sortRows";
import styled from "styled-components";

const TextBody = ({reservedBaskets,sortFields}) => {

    const arr = sortRows(reservedBaskets,sortFields,"all")

    const Td = styled.td`
      white-space: nowrap;
      text-align: center;
    `

    const normalRow = (row, i) =>
        <tr key={i}
            style={{
                fontSize: '12px',
                verticalAlign: 'middle',
                height: '46px',
                backgroundColor: i % 2 === 0 ? "rgba(242, 171, 99, 0.1)" : "rgba(49, 75, 159, 0.1)"}}>
            <Td>{row.createdAt}</Td>
            <Td>{row.orderNumber}</Td>
            <Td>{row.orderDate}</Td>
            <Td>{row.productCode}</Td>
            <Td>{row.client}</Td>
            <Td>{row.email}</Td>
            <Td >{row.bpState}</Td>
        </tr>;

    const rows = arr.map((row,i)=>normalRow(row,i))

    return (
        <tbody>{rows}</tbody>
    )
};

export default TextBody;