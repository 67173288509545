import {$authHost} from "./index";


export const fetchReservedBaskets = async () => {
    const {data} = await $authHost.get('api/basket/reserved')
    return data
}

export const setBasketProductState = async (values) => {
    const {data} = await $authHost.post('api/basket/changeState/', values)
    return data
}

export const changeStateByBasketId = async (values) => {
    const {data} = await $authHost.post('api/basket/changeStateByBasketId/', values)
    return data
}
