import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import DetailsHead from "./DetailsHead";
import DetailsTable from "./DetailsTable";
import IssueStatus from "./IssueStatus";
import styles from "./reservedProducts.module.css";
import icon_details_closed from "./img/icon-details-closed.svg"
import icon_details_open from "./img/icon-details-open.svg"
import {setBasketProductState} from "../../http/basketAPI";
import {sortRows} from "./sortRows";
import {countByCategories} from "./basketProductStates";


const TableBody = observer(({sortFields}) => {

    const {basketStore} = useContext(Context)
    const reservedBaskets = basketStore.rBaskets
    const arr = sortRows(reservedBaskets,sortFields,basketStore.stateFilter)

    const [expandInd, setExpandInd] = useState(-1)

    const changeStatus = (bpId, newState) => {
        const formData = new FormData()
        formData.append('id', bpId)
        formData.append('state', newState)

        setBasketProductState(formData)
            .then(() => {
                basketStore.setRBaskets(basketStore.rBaskets
                    .map(b => ({
                        ...b, basket_products: b.basket_products
                            .map(bp => bp.id === bpId ? {...bp, state: newState} : bp)
                    })))
                basketStore.setStateNamesAndCount(countByCategories(basketStore.rBaskets))

            })
            .catch(e => console.log("setBasketProductState error" + e))
    }


    const expandClickHandler = (id) => {
        expandInd === id ? setExpandInd(-1) : setExpandInd(id)
    }

    const normalRow = (row, i) =>
        <tr key={i} className={styles.tableBody}
            style={{backgroundColor: i % 2 === 0 ? "var(--primary-10)" : "var(--secondary-10)"}}>
            <td>{row.createdAt}</td>
            <td>{row.orderNumber}</td>
            <td>{row.orderDate}</td>
            <td>{row.productCode}</td>
            <td>{row.client}</td>
            <td>{row.email}</td>
            <td className="">
                <IssueStatus state={row.bpState}
                             setState={(newState) => changeStatus(row.bpId, newState)}/>
            </td>
            <td onClick={() => expandClickHandler(row.bpId)}>
                {expandInd === row.bpId ?
                    <img src={icon_details_open} alt="open"/> :
                    <img src={icon_details_closed} alt="closed"/>
                }
            </td>
        </tr>;

    const expandedRow = (row, i, j) =>
        <tr key={j} style={{display: expandInd === row.bpId ? 'table-row' : 'none', fontSize: 12}}>
            <td colSpan="8">
                <DetailsHead row={row}/>
                <DetailsTable basket={basketStore.rBaskets.find(b => b.id === row.basketId)} row={row}/>
            </td>
        </tr>


    let rows = []
    for (let i = 0; i < arr.length; i++) {
        rows.push(normalRow(arr[i], i))
        rows.push(expandedRow(arr[i], i, arr.length + i))
    }

    return (
        <tbody>{rows}</tbody>
    )
});

export default TableBody;
