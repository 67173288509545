import { PixelCrop } from 'react-image-crop'

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height


  canvas.width = Math.floor((crop.width-2) * scaleX)
  canvas.height = Math.floor((crop.height-2) * scaleY)

  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  ctx.drawImage(
    image,
    cropX,
    cropY,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )



  //ctx.restore()
}
