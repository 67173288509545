import React from 'react';
import iconShow from "./img/icon-show.svg"
import iconEdit from "./img/icon-edit.svg"
import iconDelete from "./img/icon-delete.svg"
import styles from "./ListButtons.module.css"


const ListButtons = ({product, delProduct, editProductHandler, setVisibility}) => {

    return (
        <div className={styles.ProductListBtn}>
            <span className={styles.IconShow}

                  onClick={() => setVisibility(product.id)}
            >
                <img src={iconShow}
                     style={product.status !== "ShowInTheShop" ? {opacity: 0.3} : {}}
                     alt="show"/>
            </span>

            <span className={styles.IconShow}
                  onClick={() => editProductHandler(product)}
            >
            <img src={iconEdit} alt="edit"/>
            </span>

            <span className={styles.IconShow3}
                  onClick={() => delProduct(product.id)}
            >
                <img src={iconDelete} alt="delete"/>
            </span>
        </div>
    )
        ;
};

export default ListButtons;