import {$authHost, $host} from "./index";

export const createAdditive = async (additive) => {
    try {
        const {data} = await $authHost.post('api/additive', additive)
        return data
    } catch (err) {
        console.log(err)
        alert("Не выполнено.\n" + err.message)
    }

}

export const updateAdditives = async (additives) => {
    const {data} = await $authHost.post('api/additive/update/',additives)
    return data
}


export const fetchAdditives = async () => {
    const {data} = await $host.get('api/additive')
    return data
}
export const fetchOneAdditive = async (id) => {
    const {data} = await $host.get('api/additive/' + id)
    return data
}
