import React, {useEffect, useRef, useState} from 'react';
import styles from './ResizableTextarea.module.css'

const ResizableTextarea = ({text, setText, externStyles}) => {
    const taRef = useRef(null)
    const [rows, setRows] = useState(1)
    const minRows = 1
    const maxRows = 200
    const e = (rows) => {
       return  rows > 1 ? {paddingTop: 10,paddingBottom: 10} : {}
    }
    const eStyles = Object.assign(e(rows),externStyles || {})

    const handleRef = () => {
        const textareaLineHeight = 30;

        if (!taRef.current) return

        const previousRows = taRef.current.rows;
        taRef.current.rows = minRows; // reset number of rows in textarea


        const currentRows = ~~(taRef.current.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            taRef.current.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            taRef.current.rows = maxRows;
            taRef.current.scrollTop = taRef.current.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows)
    };


    useEffect(() => {
        handleRef()
    }, [text, taRef?.current?.scrollHeight])


    return (
        <textarea
            ref={taRef}
            rows={rows}
            value={text}
            placeholder={'Enter your text here...'}
            spellCheck="false"
            style={eStyles}
            className={styles.textarea}
            onChange={(e) => setText(e.target.value)}/>
    );
};

export default ResizableTextarea;

