import {ButtonGroup, Dropdown} from "react-bootstrap";
import x_close_btn from "../../img/icon-delete.png";
import icon_list from "../../img/icon-list.png";
import styles from "./SelectTypes.module.css";
import {MyDropdownToggle} from "../UI/MyDropdownToggle";
import {observer} from "mobx-react-lite";
import {makeId} from "../../utils/dateTime";
import React from "react";

const SelectTypes = observer(({
                                  types,
                                  selectedTypes,
                                  setSelectedTypes,
                                  foreign_key,
                                  SelectTypeAddition,
                                  notShowAddButton,
                                  styleName,
                                  label,
                                  ...props
                              }) => {

    const isShowFlg = (selectedTypes?.length < 1) ? true : !notShowAddButton
    const addTypeId = () => {
        selectedTypes.find(selectedType => selectedType.isEmptyRow) ||
        setSelectedTypes([...selectedTypes, {id: makeId(), name: "", isEmptyRow: true,}])
    }
    const removeSelectedType = (number) => {
        setSelectedTypes(selectedTypes.filter(t => t.id !== number))
    }
    const changeSelectedType = (type, id) => {
        setSelectedTypes(selectedTypes.map(t =>
            t.id === id ? {
                ...t,
                id: type.id,
                [foreign_key]: type.id,
                name: type.name,
                price: type.price,
                isEmptyRow: false
            } : t))
    }
    const changeAdditions = (key, value, id) => {
        selectedTypes.find(selectedType => selectedType.isEmptyRow) ||
        setSelectedTypes(selectedTypes.map(t =>
            t.id === id ? {...t, [key]: value, isChanged: true} : t))
    }
    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}
            {selectedTypes?.map(v =>
                <div key={v.id} className={styles.selectTypeContainer}>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={MyDropdownToggle}
                        >
                            <div className={`${styles.selectType} ${styleName}`}
                                 style={{width: props?.width || undefined, height: props?.height || undefined}}>
                                <span>{v.name}</span>
                                <img src={icon_list} alt={">"}/>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {types.filter(t => selectedTypes.find(p => p.name === t.name) === undefined)
                                .map(type =>
                                    <Dropdown.Item key={type.id}
                                                   onClick={() => changeSelectedType(type, v.id)}
                                    >{type.name}
                                    </Dropdown.Item>
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <img src={x_close_btn} className={styles.x_close} alt={">"}
                         onClick={() => removeSelectedType(v.id)}/>

                    {SelectTypeAddition ?
                        <SelectTypeAddition change={changeAdditions} // change handler function
                                            selectedTypes={selectedTypes} // selected types array
                                            selected={v} //selected row
                                            {...props}
                        />
                        :
                        <></>
                    }

                </div>
            )}
            {selectedTypes?.length < types.length && isShowFlg ?
                <div className="button-text-only"
                     onClick={addTypeId}>
                    Přidat položku
                </div>
                :
                <></>
            }

        </div>
    );
})

export default SelectTypes;
